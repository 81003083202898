.page-footer [class*='block-template-appointment-booking-basic-responsive'] {
  display: none;
}
.enhanced-delivery-page:not([class*='enhanced_signin_page']) {
  .page-footer [class*='block-template-appointment-booking-basic-responsive'] {
    border-top: 1px solid $color-grey;
    display: block;
    margin-top: 65px;
    padding-top: 36px;
    .sitewide-footer-formatter {
      margin-bottom: 0;
      &__bottom {
        .container-vertical-top-border {
          border-top: 0;
        }
      }
    }
  }
  .sitewide-footer-formatter {
    &__footer_links {
      margin: 0 -3px;
    }
    div.footer-link {
      @include display-flex;
      margin: 0 -7px;
      &__container {
        border: 1px solid $color-black;
        color: $color-black;
        font-family: $helvetica-regular-font-family;
        font-size: 14px;
        line-height: 1.6;
        margin: 0 7px;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
        &:first-child {
          &:last-child {
            width: 100%;
          }
        }
        .chat-link {
          &::before {
            background: url('/media/images/checkout/chat.png') no-repeat 0 1px;
            background-size: 70%;
            content: '';
            display: inline-block;
            height: 24px;
            vertical-align: middle;
            width: 30px;
          }
        }
        .contact-link {
          color: $color-black;
        }
        .icon-phone {
          @include swap_direction(padding, 0 5px 0 0);
          font-family: $base-fontawesome-font-family !important;
          font-size: 24px;
          vertical-align: middle;
        }
      }
    }
    &__bottom {
      border-top-width: 0;
      @media #{$cr19-large-up} {
        border-top-width: 1px;
      }
    }
  }
}
