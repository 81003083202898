#address_book {
  #address_form_container {
    .form_element {
      select {
        width: 190px;
      }
    }
    .address5_container {
      margin-top: 6px;
    }
    .address2_container {
      margin-top: 8px;
    }
    .default_ship_container {
      .label-content {
        float: right;
        width: 170px;
        position: relative;
        bottom: 15px;
        right: 5px;
      }
    }
  }
  .address-book-info {
    h3 {
      font-size: 22px;
    }
    .column {
      margin-bottom: 10px;
      .address_display {
        a {
          margin-right: 10px;
        }
      }
    }
  }
  .address_form_container {
    .address {
      .address5_container {
        margin-top: 6px;
      }
      .address2_container {
        margin-top: 8px;
      }
      .form_element {
        select {
          width: 190px;
        }
      }
    }
  }
}

#profile_preferences {
  #newsletter-info {
    .form_element.pc_email_promo_container {
      label {
        margin-left: 6px;
      }
    }
    .sms_promotions {
      .form_element.radio {
        input[type='checkbox'] {
          float: left;
        }
        label {
          display: inline;
          float: left;
          width: 90%;
        }
      }
    }
  }
  .full-width-wrap {
    fieldset.fs {
      .form_element {
        min-height: 0px;
        margin: 0px;
      }
    }
  }
}

#top {
  .gnav-drop-down-v1 {
    .dd-content.active {
      width: auto;
    }
  }
}

.section-store-locator {
  .store-locator-body {
    .form_element.search_alternative_text {
      margin: 3px 20px 0;
    }
    .form_element.search_container {
      input.search {
        width: 225px;
        letter-spacing: 0em;
      }
    }
  }
}

.contact_us_wrap {
  #contact-form-wrap.personal-info-form-container {
    .first-column-wrap {
      .form_element.postal_code_container {
        label {
          margin-bottom: 7px;
        }
      }
    }
    .second-column-wrap {
      .form_element.registered_member_container {
        label {
          display: block;
        }
      }
    }
  }
  .category {
    .store_service {
      display: none;
    }
  }
}

.vertical-carousel-navigation-v1 {
  .header-nav-row-v1 {
    .lpEmtStarRating,
    .lpPoweredBy {
      display: none;
    }
  }
}

#shipping {
  #checkout_shipping {
    .sub_form {
      #billing_address_form_container {
        .address {
          div.form_element {
            width: 300px;
            input {
              width: 100%;
            }
            select {
              width: 100%;
            }
          }
        }
      }
      #payment-method {
        #payment-container {
          span {
            input[type='radio'] {
              margin-right: 5px;
            }
            label {
              span {
                margin-left: 18px;
                display: block;
              }
            }
            .divider {
              margin-bottom: 10px;
            }
          }
        }
      }
      .email_promotions {
        input[type='checkbox'] {
          clear: both;
        }
      }
    }
    .address_form_container {
      .address {
        .address4_container {
          clear: both;
        }
      }
    }
    .shipping-address {
      padding: 0px;
    }
  }
  #foreground-node {
    #address {
      .address1_container {
        clear: both;
      }
      .address4_container {
        clear: both;
      }
    }
  }
}

#review {
  #main.multi {
    #content {
      #review-address-info {
        td {
          word-break: break-all;
          padding-right: 25px;
        }
      }
    }
    #checkout_complete {
      .email_promotions {
        input[type='checkbox'] {
          clear: both;
        }
      }
    }
  }
  #foreground-node {
    #contact_of_sale_container {
      .agreement_button {
        margin-bottom: 15px;
      }
    }
  }
}

.account-favorites-wishlist {
  .favorite-products-grid {
    .favorite {
      .favorite-product {
        .sizes {
          width: 27%;
        }
        .shades {
          width: 29%;
        }
      }
    }
  }
}

#main.multi {
  .checkout-table {
    a {
      margin-left: 3px;
      margin-right: 3px;
      &.remove_link {
        display: inline-block;
      }
    }
  }
}

.gnav-cart-v1 {
  .cart-confirm-wrapper {
    .cart-overlay-products {
      .prod-info {
        .prod-details {
          .qty-price {
            .qty {
              float: left;
              margin-right: 35px;
            }
            .price {
              width: 58px;
              float: right;
            }
          }
        }
      }
    }
  }
}

#past_purchases {
  .past-purchases {
    .order-info {
      .order-details {
        ul {
          li {
            word-wrap: break-word;
          }
        }
        .address {
          ul {
            li {
              em {
                display: block;
              }
            }
          }
        }
      }
    }
    .order-header {
      .tracking-link {
        em {
          font-style: normal;
        }
      }
    }
    .products {
      .price {
        .coming_soon_stock_msg {
          .btn-email {
            float: left;
            margin-bottom: 5px;
            clear: both;
          }
          .btn-comingsoon {
            float: left;
            margin-bottom: 5px;
          }
          .email-me {
            display: block;
            float: left;
          }
        }
      }
    }
  }
  #foreground-node {
    iframe {
      height: 325px;
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-view-options {
    h2 {
      width: 260px;
    }
  }
  .mpp-grid {
    li.mpp-box {
      .mpp-product-hover-view {
        .btn-quickview {
          left: 31px !important;
        }
      }
    }
    .mpp-product,
    .mpp-product-hover-view {
      .product-info {
        .strike-thru {
          color: $color-darker-grey;
          text-decoration: line-through;
        }
      }
    }
  }
}

#main {
  #works-well-with-wrapper {
    .add {
      input.button[type='submit'] {
        white-space: pre-wrap;
      }
    }
  }
  section.blueocean {
    .skin_diagnostic_results {
      .diagnostic-results {
        .prod-shelf-row {
          .products {
            .product {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.my-account-auth-user-panel-v1 {
  .card {
    .my-account-past-purchases-v1 {
      h3 {
        width: 85%;
      }
    }
  }
}

#viewcart {
  #works-well-with-wrapper {
    table {
      td {
        height: 180px;
        position: relative;
        .add {
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }
}

.order_arrival {
  .add-to-bag {
    float: none;
    margin-right: 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-height: 1024px) {
  .offer-promo-v1 {
    .headline {
      font-size: 12px;
      white-space: normal;
    }
    .cta {
      font-size: 12px;
      white-space: normal;
    }
  }
}

#order_detail {
  #review-address-info {
    table {
      td {
        word-break: break-all;
      }
    }
  }
}

.container {
  .column-main {
    .spp_product_status {
      .btn-email {
        display: block;
        width: 30%;
        margin: 5px 0;
      }
    }
    .replenishment,
    .spp-product__details-ingredients {
      display: none;
    }
  }
}

#foreground-node.ob_overlay.ipad_ob_overlay {
  iframe {
    -webkit-transform: none;
  }
}

.prod-shelf-row {
  .product {
    .button {
      margin-top: 10px !important;
    }
    .email-me {
      display: block;
    }
  }
}

#footer {
  .footer-top-v1 {
    display: block !important;
  }
}

#signin {
  #foreground-node {
    width: 650px !important;
  }
}

.most-recent-purchases {
  .prod-shelf-row {
    .product {
      height: 450px;
      min-height: 0 !important;
      .my-account-sku {
        height: 100%;
        position: relative;
        .add-to-bag {
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }
}

#favorites {
  #foreground-node {
    iframe {
      height: 340px;
    }
  }
  .favorite-product {
    .spp_product_status {
      .coming_soon_stock_msg {
        .btn-primary {
          margin-top: 10px;
        }
        .email-me {
          float: left;
          margin-top: 5px;
        }
      }
    }
  }
}

#landing {
  #foreground-node {
    iframe {
      height: 340px;
    }
  }
}

.section-diagnostics {
  #foreground-node {
    height: 600px !important;
    iframe {
      height: 580px;
    }
  }
}

.pc-unsubscribe {
  width: 77%;
  margin: 0 auto;
  margin-top: 15px;
}

.email-signup-thanks {
  width: 77%;
  margin: 0 auto;
}

body {
  #header {
    .block-template-site-logo-responsive-v9 {
      padding-right: 10px !important;
      .site-logo-responsive-v1 {
        .right_logo-link {
          a {
            padding-right: 0;
          }
          a img {
            vertical-align: top;
            width: 60px;
            height: 70px;
          }
        }
      }
    }
  }
}

#sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        .price {
          min-width: 100px;
        }
      }
    }
  }
}

.cs-qa-page-tab-v1 .content .content .content {
  min-height: 270px;
}

#works-well-with-wrapper input.button.btn-shopnow {
  width: 100px;
}

.pc_spp_view {
  .full_sign_in {
    .form-text {
      height: 35px;
    }
  }
  .email_check {
    input.bv_email {
      height: 35px;
    }
  }
  .options {
    .price {
      .formatted_price2 {
        text-decoration: line-through;
      }
    }
  }
}

.signin_container {
  #sms_promotion_container {
    label {
      float: right;
      width: 97%;
      font-family: arial;
    }
  }
}

.popup-offer-v1 {
  .outer-wrap {
    padding: 0px;
  }
}

div .wp-sample-page-pc {
  padding-top: 0px !important;
}

#newsletter-info {
  p {
    margin: 20px 0;
  }
  .pc_email_promo_container {
    padding-bottom: 35px;
    input[type='checkbox'] {
      float: left;
    }
    label {
      float: left;
      width: 75%;
      margin-left: 7px;
    }
  }
  .pc_email_promo_terms_container,
  .sms_promotions {
    float: left;
    margin-top: 20px;
  }
}

.appt-book {
  .appt-book-page-header {
    .appt-book-page-header-content {
      h2 {
        font-size: 53px;
      }
    }
  }
  .appt-book-location-selector {
    .location-select,
    .location-submit,
    .state-select {
      width: 310px;
    }
  }
  .service-select {
    .service {
      min-height: 440px;
      .selection-bar {
        .selection-selector {
          width: 45%;
        }
        .selection-data {
          width: 45%;
        }
      }
      .service-details {
        min-height: 165px;
        h3.service-title {
          font-size: 28px;
        }
      }
    }
    .book-now-bar {
      .minutes {
        width: 45%;
      }
      .button {
        width: 55%;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .service {
        .selection-bar {
          .selection-selector {
            width: 45%;
          }
          .selection-data {
            width: 45%;
          }
        }
      }
      #change-services-link,
      .sign-in-link,
      .start-over {
        font-family: $base-font-family;
      }
      .book-appt-container {
        .mobile-phone-fieldset {
          float: right;
          padding: 0;
          .appt-book-mobile-prefix {
            width: 20%;
            display: inline-block;
          }
          .appt-book-mobile {
            width: 80%;
          }
        }
        .registration__email-list,
        .registration__sms-list,
        .registration__terms {
          input {
            margin-right: 8px;
          }
        }
        .registration__sms-list {
          display: block;
          float: left;
        }
        .registration__terms.error {
          color: $color-notice-pink;
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .appt-date-cal {
        background-position: 6em;
        .add-cal-title,
        .add-cal-desc {
          display: none;
        }
      }
    }
  }
  .appt-book-content-header {
    .book-now-bar {
      padding-top: 40px;
    }
  }
  .appointment-container {
    .your-lessons,
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
}

.appt-book-overlay.appt-book-book-conflict-overlay {
  .overlay-content-container {
    height: auto;
    width: 490px;
    top: 50%;
    left: 50%;
    margin-top: -95px;
    margin-left: -240px;
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-content-container {
    .canceled {
      p {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

.appt-book-overlay.appt-book-datepicker-overlay {
  .overlay-content-container {
    top: 115px;
    .overlay-close {
      right: 6px;
    }
    .pika-lendar {
      .pika-button.pika-day {
        text-align: center;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .service {
          width: 35%;
        }
        .confirm-container {
          .appt-book-book-submit {
            font-size: 15px;
          }
          .total-time {
            padding-top: 5px;
          }
        }
      }
    }
    .confirmation {
      .confirmation-container {
        .appt-date-cal {
          background-position: 4em;
        }
      }
    }
    .service-select {
      .service {
        .selection-bar {
          .selection-data {
            padding-left: 30px;
            background-position: 2px;
          }
        }
      }
    }
    .appt-book-content-header {
      .book-now-bar {
        padding-top: 75px;
      }
    }
    .appt-book-page-header {
      .appt-book-page-header-content h4 {
        font-size: 14px;
      }
      .appt-book-page-header-content h2 {
        font-size: 40px;
      }
    }
  }
}

.door_row {
  .store-locator_book-appointment {
    line-height: 2em !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .appt-book {
    .appt-book-page-header {
      .appt-book-page-header-content {
        h4 {
          font-size: 14px;
        }
      }
    }
  }
}

.slick-list {
  .slick-track {
    .slick-slide {
      min-height: 650px;
    }
  }
}
