.spc_enabled {
  .checkout-progress-bar {
    display: none;
  }
  .opc__shipping,
  .opc__review {
    .checkout-progress-bar {
      display: block;
      @media #{$cr19-large-up} {
        display: none;
      }
      .checkout-progress-bar__list {
        position: relative;
        &::before {
          background: $cr19-bg-light;
          border-radius: 10px;
          content: '';
          display: block;
          height: 20px;
        }
        li {
          position: relative;
          & > a {
            &::before {
              #{$ldirection}: 0;
              background: $color-black;
              content: '';
              display: none;
              height: 20px;
              position: absolute;
              top: -20px;
              width: 100%;
            }
          }
          &.checkout-progress-bar__list-item--complete > a::before {
            display: block;
          }
          &:first-child > a::before {
            border-bottom-#{$ldirection}-radius: 10px;
            border-top-#{$ldirection}-radius: 10px;
          }
          &:last-child > a::before {
            border-bottom-#{$rdirection}-radius: 10px;
            border-top-#{$rdirection}-radius: 10px;
          }
        }
      }
    }
  }
  .enhanced-delivery-page:not([class*='enhanced_signin_page']),
  &.enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column.left {
            .checkout-progress-bar {
              display: block;
              float: none;
              margin: auto;
              max-width: 370px;
              overflow: hidden;
              padding: 18px 0 30px;
              @media #{$cr19-large-up} {
                max-width: 465px;
                padding-bottom: 40px;
              }
              .checkout-progress_payment-bar,
              .checkout-progress_review-bar,
              .checkout-progress_shipping-bar,
              .checkout-progress-bar__list::before {
                display: none;
              }
              &__list {
                &-item {
                  color: $cr19-text-grey;
                  font-size: 14px;
                  float: $ldirection;
                  position: relative;
                  text-align: center;
                  text-transform: uppercase;
                  width: 33.33%;
                  &::before {
                    @include border-radius(50%);
                    position: relative;
                    content: '';
                    display: block;
                    margin: 0 auto 5px;
                    z-index: 2;
                    color: $cr19-text-grey;
                    background: $cr19-text-grey;
                    border: 1px solid $cr19-text-grey;
                    width: 12px;
                    height: 12px;
                  }
                  .shipping-progress-text {
                    color: $cr19-text-grey;
                    font-family: $base-bold-font-family;
                    font-weight: 700;
                    pointer-events: none;
                  }
                  &::after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: $cr19-text-grey;
                    position: absolute;
                    #{$ldirection}: -50%;
                    top: 7px;
                  }
                  &:first-child::after {
                    display: none;
                  }
                  &--shipping {
                    &::before {
                      background: $color-black;
                      border: 1px solid $color-black;
                    }
                    a.shipping-progress-text {
                      color: $color-black;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .opc__review {
    &.enhanced-delivery-page,
    .enhanced-delivery-page {
      #main.single .responsive-container {
        .pg_wrapper .column.left {
          .checkout-progress-bar__list-item {
            &--shipping,
            &--review {
              &::before {
                background: $color-black;
                border: 1px solid $color-black;
              }
              &::after {
                background: $color-black;
              }
              a.shipping-progress-text {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
}
