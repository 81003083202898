$enable_cookie-img: false !default;

.tealium {
  display: none !important;
}

#bt_notification {
  .bt-content {
    position: relative;
    border: 10px solid $color-cl-green-2;
    background: $color-white;
    z-index: 1001;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    transition: all 5s;
    -webkit-transition: all 5s; /* Safari */
    .bt-close-link {
      position: absolute;
      top: 10px;
      #{$rdirection}: 25px;
      display: block;
      width: 15px;
      height: 15px;
      background: url('/media/export/images/cookies/close_x.png') 50% 50% no-repeat;
      text-indent: -9999em;
    }
    h6 {
      @include swap_direction(margin, 0 0 5px 0);
      font-family: $base-font-family;
      color: $color-darkest-grey;
      font-weight: 400;
      font-size: 14px;
      @media #{$medium-up} {
        @include swap_direction(margin, 0 0 15px 0);
      }
    }
    .btn {
      font-family: $base-bold-font-family;
      background: $color-cl-green-2;
      text-transform: uppercase;
      text-align: center;
      display: inline-block;
      zoom: 1;
      width: 100%;
      font-weight: 400;
      padding: 6px 3px;
      line-height: 13px;
      font-size: 11px;
      color: $color-white;
      &:hover {
        background: $color-green-dark;
      }
    }
    .bt-pp-section {
      display: none;
    }
    .bt-cookie-section {
      padding: 15px 30px;
      .bt-content {
        &__link {
          border: 0;
        }
        &__sub-hdr {
          color: $color-btn-dark-gray;
        }
      }
    }
    .btn-wrap {
      margin-top: 10px;
      border: 1px solid $color-white;
      .button {
        font-family: $base-bold-font-family;
        width: 100%;
        font-weight: normal;
        padding: 6px 3px;
        line-height: 13px;
        font-size: 11px;
        height: auto;
        color: $color-white;
      }
    }
    &.bt-bottom-right {
      padding: 0;
    }
  }
  .bt-bottom {
    &-right {
      position: fixed;
      bottom: 0;
      #{$rdirection}: 0;
      width: 100%;
      z-index: 1080;
      @media #{$medium-up} {
        width: 280px;
        #{$rdirection}: 10px;
      }
    }
    &-full {
      position: fixed;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      border-bottom-width: 0;
    }
  }
}

#bt_settings {
  .bt-content {
    border: 2px solid $color-black;
    padding: 15px;
    background: $color-white;
    z-index: 1001;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 1.5em;
    border-radius: 6px;
    .bt-intro {
      &-copy {
        p {
          margin: {
            top: 1em;
            bottom: 1.5em;
          }
          padding: 0;
        }
      }
      h2 {
        @include swap_direction(margin, 0 0 1em 0);
        padding: 0;
        border: none;
        text-transform: none;
        color: $color-black;
      }
    }
    .slide {
      margin: 20px auto;
      &-toggle {
        list-style: none;
        margin: 0;
        padding: 0;
        background-size: contain;
        background-image: url('/media/export/images/cookies/m_all-off.png');
        @media #{$medium-up} {
          background: transparent url('/media/export/images/cookies/all-off.png') 50% 100% no-repeat;
        }
        li {
          cursor: pointer;
          float: #{$ldirection};
          width: 82px;
          height: 136px;
          @media #{$medium-up} {
            width: 175px;
            height: 148px;
          }
          &.selected {
            & + .mask {
              border: 0;
            }
          }
          span {
            font-weight: bold;
            text-align: center;
            margin: 5px;
            display: none;
          }
        }
      }
      &,
      &-toggle {
        position: relative;
        overflow: hidden;
        width: 246px;
        @media #{$medium-up} {
          width: 525px;
        }
      }
      @if $enable_cookie-img == true {
        &-toggle {
          background: url('/media/export/images/cookies/m_all-off_v1.png') no-repeat;
          width: 288px;
          background-size: 288px 148px;
          @media #{$medium-up} {
            background: url('/media/export/images/cookies/all-off_v1.png');
            width: 525px;
            background-size: 525px 148px;
          }
          li {
            width: 144px;
            height: 148px;
            @media #{$medium-up} {
              width: 262px;
            }
          }
        }
        &,
        &-toggle {
          width: 288px;
          @media #{$medium-up} {
            width: 525px;
          }
        }
      }
    }
    .mask {
      position: absolute;
      overflow: hidden;
      top: 0;
      #{$ldirection}: 0;
      width: 0;
      border-#{$rdirection}: 1px solid $color-grey-light;
      transition: width 1s;
      -webkit-transition: width 1s; /* Safari */
      .slide-toggle {
        background-image: url('/media/export/images/cookies/m_all-on.png');
        @media #{$medium-up} {
          background-image: url('/media/export/images/cookies/all-on.png');
        }
      }
      .drag {
        position: absolute;
        width: 26px;
        height: 11px;
        background: transparent url('/media/export/images/cookies/icon_arrow.png') 50% 100% no-repeat;
        #{$rdirection}: 30px;
        bottom: 11px;
        @media #{$medium-up} {
          bottom: 12px;
          #{$rdirection}: 70px;
        }
      }
      @if $enable_cookie-img == true {
        .slide-toggle {
          background: url('/media/export/images/cookies/m_all-on_v1.png') no-repeat;
          background-size: 288px 148px;
          @media #{$medium-up} {
            background-image: url('/media/export/images/cookies/all-on_v1.png');
            background-size: 525px 148px;
            margin: 0;
          }
        }
        .drag {
          #{$rdirection}: 58px;
          @media #{$medium-up} {
            #{$rdirection}: 120px;
          }
        }
      }
    }
    .bt-privacy-info {
      &.selected {
        display: block;
      }
      h3 {
        @include swap_direction(padding, 1em 1em 1em 40px);
        background-position: 10px 50%;
        background-repeat: no-repeat;
        background-color: $color-grey-dark;
      }
      .bt-privacy-will {
        h3 {
          background-image: url('/media/export/images/cookies/icon_safe_lock.png');
        }
        li {
          background-image: url('/media/export/images/cookies/icon_safe_thick.png');
        }
        &not {
          h3 {
            background-image: url('/media/export/images/cookies/icon_unsafe_lock.png');
          }
          li {
            background-image: url('/media/export/images/cookies/icon_unsafe_thick.png');
          }
        }
        &,
        &not {
          width: 100%;
          float: none;
          margin-#{$rdirection}: 2%;
          @media #{$medium-up} {
            width: 48%;
            float: #{$ldirection};
          }
          ul {
            list-style-type: none;
            padding-#{$ldirection}: 0;
            margin: 1em;
            li {
              margin: 0.8em 0;
              padding-#{$ldirection}: 30px;
              background-position: 0 50%;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
  .bt-privacy-info {
    display: none;
    overflow: hidden;
  }
}
