//colors
$color_silver_chalice_approx: #aaa;
$color_mine_shaft_approx: #333;
$color_pink_approx: #fcc;
$color_coral_tree_approx: #a66;
$color_cream_approx: #ffc;
$color_green_smoke_approx: #aa6;
$color_snowy_mint_approx: #cfc;
$color_fern_approx: #6a6;
$color_sail_approx: #bdf;
$color_bermuda_gray_approx: #68a;
$black_10: rgba(0, 0, 0, 0.1);
$color_mountain_mist_approx: #999;
$color_gallery_approx: #ededed;
$color_alto_approx: #dcdbdb;
$color_baltic_sea_approx: #2b2b2b;
$color_tapa_approx: #737272;
$color_clinique_default: #5dba98;
$color_suva_gray_approx: #888888;

//fonts
$font_0: HelveticaNeueLTStd55Roman;
$font_1: arial;
$font_2: verdana;
$font_3: icomoon;
$font_4: sans-serif;
//urls
$url_0: url(/media/export/images/checkout/click_collect/map_close.jpg);
$url_1: url(/media/export/images/checkout/click_collect/map_panel_close.jpg);
$url_2: url(/media/export/images/checkout/click_collect/map_search.jpg);
$url_3: url(/media/export/images/checkout/click_collect/map_mobile_close_down.jpg);
$url_5: url(/media/export/images/checkout/click_collect/map_geolocate.jpg);
$url_6: url(/media/export/images/checkout/click_collect/map_mobile_panel_close.jpg);

.viewcart-buttons-panel .continue-buttons a.paypal-checkout.disabled span.paypal-message {
  font-size: 0.8em;
  text-align: center;
  display: block;
  color: #998;
}

.viewcart-buttons-panel .continue-buttons a.continue-checkout {
  line-height: 1.65 !important;
}

.gm-style .gm-style-iw {
  width: 100% !important;
  padding: 6px;
  line-height: 18px;
}

#shipmethod-panel .ship-method-group-label {
  span.ship_radio_wrapper {
    margin-left: 10px;
    input,
    label {
      display: inline;
    }
    input {
      // height: auto;
      // margin-right: 10px;
    }
    a.click-and-collect.more-info {
      padding: 1px 0 1px 24px;
      margin-left: 1em;
      background: transparent url(/media/export/images/checkout/info-cl.gif) no-repeat left center;
    }
  }
}

#viewcart.device-mobile,
.device-mobile {
  #shipmethod-panel div.content {
    float: none;
    .ship-method-group-label span.ship_radio_wrapper {
      display: block;
      padding: 10px 0;
      input {
        display: inline;
      }
    }
  }
}

#shipping-panel div.address-to-use {
  span.address-to-use {
    label,
    input {
      display: inline;
    }
  }
}

.column.right #shipmethod-panel .ship-method-group-label span.ship_radio_wrapper {
  margin: 0;
  input {
    margin-left: 0.5em;
  }
}

#review-panel .form-container .address-to-use {
  padding-left: 1px;
  label {
    float: left;
    margin-right: 10px;
  }
}

#checkout_shipping_panel {
  div.address-to-use {
    width: 100%;
    padding: 3px 0 3px 3px;
    margin: 0 20px 10px 0;
    div.shipmethod-delivery {
      float: left;
      width: auto;
    }
  }
  .select-address .click-and-collect {
    margin: 0px !important;
    .local-collection {
      .set-collection-point .form-item {
        width: auto;
        a {
          display: block;
          margin: 0 0 10px;
        }
      }
      .show-collection-point {
        a.local-collection-link {
          color: $color_clinique_default;
          &:hover {
            color: #44a07e;
          }
        }
      }
    }
    .store-collection .set-collection-point .form-item {
      width: auto;
      a {
        display: block;
        margin: 0 0 10px;
      }
    }
  }
}

#checkout_shipmethod .ship-method-group-label {
  input[type='radio'] {
    float: left;
    display: inline-block !important;
  }
  label {
    float: left;
    display: inline-block !important;
  }
  .shipmethod-group {
    float: none;
    width: 100%;
  }
}

#shipmethod-panel {
  .ship-method-group {
    width: 100%;
    overflow: hidden;
  }
  .ship-method-group-label {
    overflow: hidden;
    float: right;
    padding: 3px 0 0 3px;
    input {
      float: left;
      height: auto;
      margin-left: 20px;
    }
    label {
      line-height: 1.2em;
      margin: 7px 0 0 4px;
      a.click-and-collect.more-info {
        padding: 1px 0 1px 24px;
        margin-left: 1em;
        background: transparent url(/media/export/images/checkout/info-cl.gif) no-repeat left center;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .ship-method-home-group {
    overflow: hidden;
    label {
      display: inline !important;
    }
  }
}

.select-address div.shipmethod-delivery {
  input {
    position: relative;
    top: 2px;
  }
  label {
    display: inline;
    font-weight: normal !important;
    text-transform: none !important;
    margin-bottom: 0 !important;
  }
}

div.ship-method-collection-group div.form-item label {
  text-transform: none !important;
}

.right {
  #checkout_shipmethod .ship-method-group-label {
    input[type='radio'] {
      float: left;
      display: inline !important;
      margin-right: 3px;
    }
    label {
      margin: -1px 8px 0 0;
      a.more-info {
        float: right;
      }
    }
    .shipmethod-group {
      float: left;
    }
  }
  #shipmethod-panel {
    header {
      h2 {
        float: left !important;
      }
    }
    .ship-method-group-label {
      float: left;
      input {
        float: left;
        height: auto;
        margin-left: 0;
      }
    }
    span.ship_radio_wrapper {
      margin: 0;
      display: table;
      padding-top: 6px;
    }
  }
}

//@extend-elements
//original selectors
//.map-container .address-map-search-panel .search-box-submit, .map-container .address-map-search-panel .search-box-toggle,  .map-container .address-map-search-panel .search-box-geolocate, .map-container .address-map-search-panel .search-box-cancel
%click-collect-1 {
  width: 32%;
  font-size: inherit;
  margin-right: 2px;
  float: left;
  clear: none;
}

//original selectors
//.map-container .address-map-directions-panel .locations, .map-container.address-map-location-panel .locations
%click-collect-2 {
  height: 93.5%;
  overflow-y: scroll;
  background-color: $color-white;
}

//original selectors
//.map-container .address-map-directions-panel .location,.map-container .address-map-location-panel .location
%click-collect-3 {
  cursor: pointer;
  padding: 1em 0.5em;
  margin: 0;
  border-bottom: 1px solid $color_celeste_approx;
  overflow: hidden;
  padding: 5px;
  margin: 0 0 1em;
  border: 1px solid $color_gallery_approx;
  color: $color-black;
  transition: padding 0.5s, background 0.5s;
  margin: 5px;
  font-weight: 11px;
}

//original selectors
//.map-container .address-map-location-panel .location:hover,.map-container .address-map-location-panel .location.selected
%click-collect-4 {
  padding: 0;
  padding: 5px;
  background: $color-white;
  border: 1px solid $color_clinique_default;
}

//original selectors
//.map-container .address-map-directions-panel .location .image, .map-container .address-map-location-panel .location .image
%click-collect-5 {
  float: left;
  width: 100px;
  margin: 0 1em 0 0;
}

//original selectors
//.map-container .address-map-directions-panel .location .title span, .map-container .address-map-location-panel .location .title span
%click-collect-6 {
  font-weight: bold;
  font-size: 11px;
  border: 0;
  padding: 0.5em 0;
  margin-bottom: 10px;
}

//original selectors
//.map-container .address-map-directions-panel .location div.button, .map-container .address-map-location-panel .location div.button
%click-collect-7 {
  clear: both;
  margin-top: 1em;
  border: none;
  background: none;
  width: 100%;
  padding: 0;
}

//original selectors
//.click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-submit, .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-toggle,  .click_and_collect_map .overlay-content .map-container .search-box-cancel
%click-collect-8 {
  padding: 6px;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

//original selectors
//.click_and_collect_map .overlay-content .map-container .address-map-directions-panel,.click_and_collect_map .overlay-content .map-container .address-map-location-panel
%click-collect-9 {
  top: 55px;
  bottom: 0;
  display: block;
  position: absolute;
  left: -275px;
  width: 300px;
  padding-right: 24px;
  z-index: 2;
  transition: left 0.5s;
}

//original selectors
//.click_and_collect_map .overlay-content .map-container .address-map-directions-panel header, .click_and_collect_map .overlay-content .map-container .address-map-location-panel header
%click-collect-10 {
  width: 288px;
  padding-top: 10px;
  height: 40px;
  background-color: $color-white;
}

//original selectors
//.click_and_collect_map .overlay-content .map-container .address-map-location-panel a.close, .click_and_collect_map .overlay-content .map-container .address-map-directions-panel a.close
%click-collect-11 {
  display: block;
  position: absolute;
  right: 6px;
  top: 16px;
  text-decoration: none;
  z-index: 3;
  margin: 0;
  background: none;
  width: 13px;
  height: auto;
  text-indent: 100%;
}

#map-overlay-background {
  background-color: $color-white;
  opacity: 1;
}

.local-collection-map {
  height: 100%;
  iframe {
    border: 0;
    width: 100%;
    height: 97%;
  }
}

.map-container {
  map {
    height: 300px;
  }
  .address-map-search-panel {
    font-size: 1em;
    * {
      //Instead of the line below you could use @include box-sizing($bs)
      box-sizing: border-box;
    }
    .search-box {
      overflow: hidden;
      label {
        display: block;
        font-size: inherit;
        margin: 0 0 1em;
        font-weight: bold;
      }
    }
    .search-box-field {
      width: 100%;
      font-size: inherit;
      margin: 0;
    }
    .search-box-submit {
      @extend %click-collect-1;
      margin-left: 0;
    }
    .search-box-toggle {
      @extend %click-collect-1;
      margin-left: 0;
    }
    .search-box-geolocate {
      @extend %click-collect-1;
    }
    .search-box-cancel {
      @extend %click-collect-1;
    }
    .status-bar-wrapper {
      width: 80%;
      margin-left: 10%;
      .status-bar .message {
        padding: 0.5em;
        border: 1px solid $color_silver_chalice_approx;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 0 2px 4px $color_mine_shaft_approx;
        &:first-child {
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 0 0 5px 5px;
        }
        .close {
          float: right;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        &.error {
          background: $color_pink_approx;
          border-color: $color_coral_tree_approx;
        }
        &.warning {
          background: $color_cream_approx;
          border-color: $color_green_smoke_approx;
        }
        &.success {
          background: $color_snowy_mint_approx;
          border-color: $color_fern_approx;
        }
        &.information {
          background: $color_sail_approx;
          border-color: $color_bermuda_gray_approx;
        }
      }
    }
  }
  .address-map-directions-panel {
    display: none;
    .locations {
      @extend %click-collect-2;
    }
    .location {
      @extend %click-collect-3;
      p {
        margin: 0 0 0.5em;
      }
      .image {
        @extend %click-collect-5;
        img {
          width: 100%;
        }
      }
      .distance {
        float: right;
        color: $color_mountain_mist_approx;
        .uom {
          text-transform: lowercase;
        }
      }
      .location_description {
        font-style: italic;
      }
      .title span {
        @extend %click-collect-6;
      }
      div.button {
        @extend %click-collect-7;
      }
    }
  }
  .address-map-location-panel {
    display: none;
    .locations {
      @extend %click-collect-2;
      @if $text-direction == rtl {
        text-align: right;
      }
    }
    .location {
      @extend %click-collect-3;
      &:hover {
        @extend %click-collect-4;
      }
      &.selected {
        @extend %click-collect-4;
      }
      p {
        margin: 0 0 0.5em;
      }
      .image {
        @extend %click-collect-5;
        img {
          width: 100%;
        }
      }
      .distance {
        float: right;
        color: $color_mountain_mist_approx;
        .uom {
          text-transform: lowercase;
        }
      }
      .location_description {
        font-style: italic;
      }
      .title {
        span {
          @extend %click-collect-6;
        }
        .distance {
          display: none;
        }
      }
      div.button {
        @extend %click-collect-7;
      }
    }
    .map .location {
      min-width: 200px;
    }
  }
  .map-info-window {
    border: 1px solid $color_mountain_mist_approx;
    padding: 10px;
    background-color: $color-white;
    .show-access-point {
      display: none;
    }
    .location {
      width: 238px;
      padding: 0 5px;
      .title {
        padding-bottom: 5px;
        font-weight: bold;
      }
    }
    .title .distance {
      display: none;
    }
  }
  .directions {
    background: $color-white;
    color: $color-white;
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: 100%;
    .button {
      width: 96%;
      margin: 5px;
    }
  }
}

.map-info-window {
  .location {
    div.button {
      clear: both;
      margin-top: 1em;
      border: none;
      background: none;
      width: 100%;
      padding: 0;
      a.select-access-point {
        margin-bottom: 5px;
      }
    }
    .image img {
      max-width: 100% !important;
    }
  }
}

.click_and_collect_map {
  width: 100%;
  position: fixed;
  top: 0;
  .close-container {
    display: none;
  }
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      .address-map-search-panel {
        position: absolute;
        top: 0;
        z-index: 1;
        margin-left: 30px;
        margin-top: 5px;
        border-top: 0;
        @if $text-direction == rtl {
          right: 5px;
        }
        .status-bar-wrapper {
          position: fixed;
          bottom: 20px;
          left: 50%;
          width: 30em;
          margin-left: -15em;
          .status-bar .message {
            border-top: 0;
            &:first-child {
              //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
              border-radius: 0;
            }
          }
        }
        .search-box-field {
          float: left;
          width: 400px;
          padding: 5px;
        }
        .search-box-submit {
          width: 30px;
          @extend %click-collect-8;
          background: transparent $url_2 no-repeat center;
          .loading span:before {
            content: '';
          }
          .search-text {
            display: none;
          }
        }
        .search-box-toggle {
          width: 50px;
          @extend %click-collect-8;
          .loading span:before {
            content: '';
          }
        }
        .search-box-geolocate {
          width: 50px;
        }
        .search-box-cancel {
          width: 50px;
        }
      }
      .search-box-cancel {
        @extend %click-collect-8;
        position: absolute;
        top: 0;
        right: 0.5em;
        margin: 5px 0 0;
        z-index: 1;
        background: transparent $url_0 no-repeat center;
        .loading span:before {
          content: '';
        }
      }
      .search-box-geolocate {
        width: auto;
        height: auto;
        background: $color_tapa_approx;
        background: $color_clinique_default $url_5 no-repeat center;
        position: absolute;
        width: 49px;
        height: 50px;
        right: 0.5em;
        bottom: 5%;
        z-index: 1;
        overflow: hidden;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 50%;
        color: $color-black;
        text-align: center;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 10px;
        .loading span:before {
          content: '';
        }
      }
      .address-map-directions-panel {
        @extend %click-collect-9;
        left: -280px;
        left: 0;
        header {
          @extend %click-collect-10;
          height: 41px;
          float: left;
          h2 {
            font-size: 17px;
            padding-left: 0.5em;
            float: left;
            margin-right: 4px;
          }
          .button {
            float: left;
            padding: 0 3px;
            font-size: 10px;
            border: 0;
            line-height: 23px;
          }
          div a.close span:before {
            display: block;
            font-family: $font_3;
            speak: none;
            font-size: 16px;
            color: $color-white;
            text-decoration: none;
          }
        }
        .directions {
          float: left;
        }
        a.close {
          @extend %click-collect-11;
          background: $url_0 no-repeat;
          height: 42px;
          width: 15%;
          top: 0;
          right: -5px;
          display: block;
          position: absolute;
          text-decoration: none;
          z-index: 1;
        }
      }
      .address-map-location-panel {
        @extend %click-collect-9;
        header {
          @extend %click-collect-10;
          width: 288px;
          h2 {
            font-size: 17px;
            padding-left: 0.5em;
          }
        }
        &.active {
          left: 0;
          a.close span:before {
            content: '';
          }
        }
        a.close {
          @extend %click-collect-11;
          background: $url_1 no-repeat 0px -5px;
          height: 41px;
          width: 30px;
          top: 0;
          right: -5px;
          display: block;
          position: absolute;
          text-decoration: none;
          z-index: 1;
          span:before {
            display: block;
            content: '';
            font-size: 16px;
            text-decoration: none;
          }
        }
      }
      .map {
        height: 100%;
        width: 100%;
      }
      .active a.close {
        background: $color-black $url_0 no-repeat -5px 0;
        right: 11px;
      }
    }
    .search-box-cancel span:before {
      padding-top: 5px;
    }
    .search-box-geolocate span:before {
      font-size: 25px;
      line-height: 25px;
      padding: 2px 0 2.5px 3.5px;
    }
  }
  &.local-collection .overlay-content .map-container .address-map-search-panel .search-box-toggle {
    width: 80px;
  }
  &.store-collection .overlay-content .map-container .address-map-search-panel .search-box-toggle {
    width: 80px;
  }
}

#map-foreground-node {
  padding: 0;
}

.adp {
  margin-top: 10px;
}

.adp-placemark {
  background-color: $color-white;
  td {
    padding: 2px 0 2px 15px;
    color: $color-black;
  }
}

.address-map-location-panel .button {
  overflow: hidden;
  a {
    padding: 4px !important;
  }
}

html.mac .click_and_collect_map .overlay-content .map-container {
  .address-map-directions-panel header {
    height: 40px !important;
  }
  .address-map-location-panel header {
    height: 40px !important;
  }
}

div.map {
  display: block !important;
}
//@extend-elements
//original selectors
//.adp-step, .adp-substep
%click-collect-12 {
  padding: 0.7em 3px;
  line-height: 1.26em;
  font-size: 11px;
}

.adp-step {
  @extend %click-collect-12;
}

.adp-substep {
  @extend %click-collect-12;
}

#adp-placemark {
  font-size: 11px;
}

.adp-summary {
  font-size: 11px;
}

.adp-placemark .adp-text {
  font-size: 13px;
}

.directions .adp-summary span {
  &:nth-child(1) {
    display: block;
    text-align: right;
    padding-right: 10px;
    float: right;
  }
  &:nth-child(2) {
    display: none;
  }
  &:nth-child(3) {
    padding-left: 70px;
    text-transform: capitalize;
    float: left;
  }
}

table.adp-directions tr td .adp-distance {
  padding-right: 10px;
}

.no-locations-found {
  padding: 0;
  border: 0;
  font-weight: bold;
}

.locations .messages {
  padding: 0;
  margin: 10px;
  background-color: transparent;
  border: 0;
}

@media (max-width: 480px) {
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel {
      width: auto;
      margin: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      border-width: 0 0 1px 0;
      padding: 0 0 1em;
    }
    .address-map-location-panel {
      top: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      &.hover {
        left: 0;
      }
      &.active {
        top: 0;
        height: auto;
      }
      header {
        padding-top: 5px;
      }
      .location:first-child {
        padding-top: 5px;
        margin-top: 5px;
      }
    }
  }
}

@media (max-width: 530px) {
  section.viewcart-buttons-panel .continue-buttons a {
    margin-bottom: 0 !important;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    width: 290px;
    top: 50px !important;
    .search-box-field {
      font-size: 12px;
      width: 90%;
      float: left;
      height: 27px;
    }
    .search-box-submit {
      margin: 1px 0 0;
      width: auto;
      padding: 0;
      height: 26px;
      width: 26px;
    }
  }
}

@media (max-width: 850px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    .search-box-field {
      width: 100%;
      margin: 0;
      float: none;
      clear: both;
    }
    .search-box-submit {
      margin-top: 1em;
      width: 32%;
      margin-left: 0;
    }
    .search-box-toggle {
      margin-top: 1em;
      width: 32%;
      margin-left: 0;
    }
    .search-box-geolocate {
      margin-top: 1em;
      width: 32%;
    }
    .search-box-cancel {
      margin-top: 1em;
      width: 32%;
    }
  }
}

@media (max-width: 768px) {
  .map-container {
    .address-map-search-panel .search-box-field {
      width: 30%;
    }
    .map-info-window .location {
      width: 100%;
      padding: 0;
      .title {
        padding-bottom: 0;
      }
    }
    .location .title span {
      line-height: 1.1em;
    }
    .address-map-location-panel .location {
      .distance {
        display: none;
      }
      .title .distance {
        display: block;
        .uom {
          color: $color_suva_gray_approx;
        }
      }
    }
  }
  #map-foreground-node {
    z-index: 1000000;
  }
  .click_and_collect_map .overlay-content {
    .map-container {
      .address-map-search-panel {
        width: 300px;
        top: 30px;
        .search-box-submit {
          float: left;
          padding: 0 !important;
          width: 10%;
          margin: 0;
          height: 30px;
        }
        .status-bar-wrapper {
          margin: 20px;
          position: fixed;
          bottom: 50px;
          width: 100%;
          margin: 0;
          left: 0;
          text-align: center;
        }
        .search-box-field {
          font-size: 12px !important;
          letter-spacing: normal;
          width: 89%;
          float: left;
          height: 30px;
        }
      }
      .map-info-window {
        max-width: 150px;
        .location .image {
          display: none;
        }
      }
      .address-map-directions-panel {
        width: 100%;
        top: 0;
        max-height: 100%;
        background-color: $color-white;
        padding-right: 0;
        header {
          width: 100%;
          h2 {
            padding-top: 5px;
            line-height: 0px !important;
            font-size: 20px;
            font-weight: bold;
          }
        }
        a.close {
          right: 0;
          height: auto;
          width: 8%;
          background: $color-white $url_6 no-repeat 0 0;
          text-indent: 100%;
        }
        .active a.close {
          right: 0;
          height: auto;
          width: 8%;
          background: $color-white $url_3 no-repeat 0 0;
          text-indent: 100%;
        }
      }
      .address-map-location-panel {
        width: 100%;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 30px;
        margin: 0;
        padding-bottom: 0;
        overflow: hidden;
        header {
          width: 100%;
          padding-top: 10px;
          h2 {
            padding-top: 5px;
            line-height: 0px !important;
            font-size: 20px;
            font-weight: bold;
          }
        }
        &.active {
          top: 0;
          height: auto;
        }
        a.close {
          right: 0;
          background: $color-white $url_6 no-repeat 0 0;
          border: 0;
          text-indent: -999em;
          span:before {
            content: '\f0d8';
          }
        }
      }
      .search-box-geolocate {
        bottom: 20%;
      }
      .active a.close {
        right: 0;
        background: $color-white $url_3 no-repeat 0 0;
        text-indent: 100%;
      }
    }
    .search-box-cancel span:before {
      padding-top: 0;
    }
  }
  #shipmethod-panel .ship-method-group-label {
    float: left;
    width: 100%;
    padding: 10px 0 0 0px;
    .ship_radio_wrapper {
      clear: both;
      width: 100%;
      margin-left: 0 !important;
    }
    input {
      width: 6%;
      margin: 1px 0px 0px 0px !important;
    }
    label {
      width: 93%;
      margin: 0 !important;
      a.more-info {
        float: right;
      }
      .overlay-wrapper {
        position: relative;
        left: -15px;
        display: inline;
      }
    }
  }
  #samples #main.single {
    .samples-page {
      .viewcart-buttons-panel .content .continue-buttons {
        a.continue-checkout {
          line-height: 2.5em !important;
        }
      }
    }
  }
  .viewcart-buttons-panel .continue-buttons {
    a.continue-checkout {
      margin: 10px 0;
      line-height: 3.5em !important;
    }
    a.samples-checkout {
      margin: 10px 0;
      line-height: 2.7em !important;
    }
    .paypal-checkout.disabled {
      margin: 0;
    }
  }
  .address-map-location-panel header h2 {
    margin-top: 0;
    @if $text-direction == rtl {
      text-align: right;
    }
  }
  .search-box-wrapper .search-box .search-box-submit {
    height: 200px;
  }
  .map-info-window .location div.button {
    height: auto;
    a {
      width: 100%;
      text-align: center;
      margin: 0px;
      height: auto;
      font-size: 0.9em;
      line-height: 2em;
      &.select-access-point {
        margin-bottom: 5px;
      }
    }
  }
  .adp-substep:nth-child(3) {
    //Instead of the line below you could use @include word-break($value)
    word-break: break-word;
  }
  .directions .adp-directions {
    width: 100%;
  }
  .left #checkout_shipmethod .ship-method-group-label {
    input[type='radio'] {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 6px;
      padding: 0.2em 0 !important;
      margin-right: 1% !important;
    }
    label .overlay-wrapper p {
      letter-spacing: 0.15em;
    }
  }
  div#main.single {
    .panel .form-item.sms-updates {
      float: left;
      width: 100%;
    }
  }
}

.local-collection {
  .checkout-buttons.local-collection-button {
    font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
    text-transform: uppercase;
    color: white;
    font-size: 0.9em;
    padding: 4px 10px 3px;
    display: inline-block;
    @if $cr19 == true {
      background-color: $cr19-bg-black;
    } @else {
      background-color: #5dba98;
    }
  }
  .show-collection-point {
    a.local-collection-link {
      @if $cr19 == true {
        color: $cr19-text-black;
      } @else {
        color: #5dba98;
      }
    }
  }
}

@if $text-direction == rtl {
  %click-collect-9 {
    @media (min-width: 769px) {
      right: -275px;
      padding-right: 0;
      padding-left: 24px;
    }
  }
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .address-map-search-panel {
          .search-box {
            display: flex;
            @if $text-direction == rtl {
              flex-direction: row-reverse;
            }
          }
        }
        .search-box-cancel {
          right: inherit;
          left: 0;
        }
        .search-box-geolocate {
          right: inherit;
          left: 0.5em;
        }
        a.close {
          background: $url_1 no-repeat 5px -5px;
          transform: rotate(0deg) scaleX(-1);
        }
        .address-map-location-panel {
          @media (min-width: 769px) {
            left: inherit;
            right: -277px;
          }
          &.active {
            left: inherit;
            right: 0;
          }
          a.close {
            @media (min-width: 768px) {
              left: inherit;
              right: 275px;
            }
            @media (max-width: 768px) {
              right: unset;
              left: 0;
            }
          }
        }
      }
    }
  }
}
