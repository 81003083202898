.olapic-emea-v1 {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;
  .page-spp & {
    max-width: none;
    padding: 20px 15px;
    @media #{$medium-up} {
      border-bottom: 1px solid $color-shade-grey;
      padding: 0 0 40px 0;
    }
  }
  &__container {
    position: relative;
    .page-spp & {
      max-width: 960px;
      margin: 0 auto;
    }
  }
  &__body {
    font-size: 14px;
    @media #{$medium-up} {
      font-size: 15px;
      font-family: $base-font-family;
    }
  }
  &__header {
    text-align: center;
    margin: 0 0 20px 0;
    padding: 0 20px;
    @media #{$medium-up} {
      margin-bottom: 30px;
    }
  }
  &__heading {
    color: $color-black;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 26px;
    @media #{$medium-up} {
      font-size: 36px;
      line-height: 38px;
      margin-bottom: 13px;
    }
  }
}
