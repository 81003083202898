/**
 * @file tr_pc.scss
 *
 * \brief Pull all parts of the TR-PC theme together into one file
 */

@import 'overrides/overrides';
@import '../../cl_base/scss/cl-base-pc';
@import '../../emea_base/scss/emea_base_pc';

@import 'components/social_login';
@import 'components/pc/all';
@import 'sections/power-review/power_reviews_pc';
@import 'sections/account_reset_password';
@import 'sections/advlegal';

.spc_enabled {
  @import 'sections/checkout_opc';
}

//Loyalty
@import '../../cl_base/scss/loyalty/loyalty_sign_in';
@import '../../cl_base/scss/loyalty/loyalty_global';
@import '../../cl_base/scss/loyalty/loyalty_checkout_confirmation';
@import '../../emea_base/scss/components/loyalty';

@import 'sections/_loyalty';

@import 'sections/enhanced-delivery';
