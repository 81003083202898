.spc_enabled {
  &.enhanced-delivery-page,
  .enhanced-delivery-page {
    #main.single {
      .responsive-container {
        .ship-method-group-pack-active .new-address {
          .delivery_instructions {
            display: none;
          }
        }
        .ship-method-group {
          @include display-flex;
          margin: 25px 7px;
          @media #{$cr19-large-up} {
            margin: 25px -10px;
          }
        }
        .ship-method-group-item,
        .ship-methods {
          background: url('/media/images/checkout/home_delivery_icon.png') no-repeat center 22px;
          border: 1px solid;
          border-radius: 2px;
          cursor: pointer;
          margin: 0 10px;
          opacity: 0.3;
          padding: 55px 0 20px;
          text-align: center;
          text-transform: uppercase;
          width: 50%;
          &--pack {
            background-image: url('/media/images/checkout/icon-location.svg');
          }
          &.active {
            opacity: 1;
          }
        }
        .ship-method-clickcollect {
          background-image: url('/media/images/checkout/icon-location.svg');
        }
        .pg_wrapper {
          .column.left {
            .ship-method-group-pack-active {
              .new-address-container {
                .address1 {
                  .valid_marker,
                  .invalid_marker {
                    display: none;
                  }
                  label {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }
              .checkout__subtitle {
                .delivery_title {
                  display: none;
                }
                .packstation_title {
                  display: inline-block;
                  position: relative;
                  .info-link-message {
                    #{$ldirection}: auto;
                    #{$rdirection}: -30px;
                    display: inline-block;
                    height: 20px;
                    margin: 0;
                    opacity: 0.4;
                    position: absolute;
                    top: 3px;
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .checkout__subtitle {
    .packstation_title {
      display: none;
    }
  }
  .ship-method-group-pack-active .delivery-address-container.delivery_home,
  .ship-method-group-home-active .delivery-address-container.delivery_pack,
  #main.single .ship-method-group-pack-active .select-address .company-name {
    display: none;
  }
}
