.spc_enabled {
  .enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      #shipping-panel {
        .form-item {
          &.select {
            &::after {
              border: 5px solid transparent;
              border-top-color: $color-black;
              bottom: 20px;
              content: '';
              pointer-events: none;
              position: absolute;
              #{$rdirection}: 33px;
            }
            .label-content {
              display: none;
            }
          }
          &.country-id {
            padding: 14px 0;
            &.select::after {
              display: none;
            }
            label {
              display: block;
              margin: 0;
              .label-content {
                display: block;
              }
            }
          }
        }
        .billing-address-form-fields {
          .country-id {
            border: 1px solid $color-gray-dusty;
          }
          .bill-to-shipping {
            display: none;
          }
        }
      }
    }
  }
}
