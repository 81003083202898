.spc_enabled {
  .enhanced-delivery-page:not([class*='enhanced_signin_page']),
  &.enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column {
            &.left {
              .email-and-sms-promotions {
                margin-top: 6px;
                @media #{$cr19-large-up} {
                  margin-top: 20px;
                }
                .promotions-section,
                .divide {
                  margin-bottom: 6px;
                  @media #{$cr19-large-up} {
                    margin-bottom: 18px;
                  }
                  .promotions-container {
                    padding: 9px 16px;
                    border-bottom: 1px solid $color-grey;
                    border-top: 1px solid $color-grey;
                    background: $color-white;
                    @media #{$cr19-large-up} {
                      padding: 6px 15px;
                      margin-bottom: 16px;
                    }
                  }
                  input {
                    height: 18px;
                    width: 18px;
                  }
                  label {
                    @include display-flex;
                    font-family: $helvetica-regular-font-family;
                    letter-spacing: 0.25px;
                  }
                  p {
                    margin: 0;
                  }
                }
                .promotions-section {
                  margin-bottom: 14px;
                }
                .email_promotions {
                  margin: 17px 0 10px;
                  p {
                    margin: 0;
                    &:first-of-type {
                      display: none;
                    }
                    &.quick-wins-hidden {
                      font-size: 12px;
                      margin: 0 0 10px;
                      display: block;
                    }
                  }
                  .email-promotion-link {
                    @include display-flex;
                    margin: 8px 0 0;
                  }
                  .sms-mobile-phone {
                    input {
                      @include appearance(none);
                      background-image: none;
                      width: 100%;
                      height: 54px;
                      margin-top: 20px;
                    }
                    label {
                      font-size: 16px;
                      position: relative;
                      bottom: 8px;
                    }
                    .invalid_marker {
                      #{$rdirection}: 35px;
                      top: 30px;
                    }
                    .valid_marker {
                      top: 29px;
                      #{$rdirection}: 20px;
                    }
                  }
                }
              }
              .newsletter__accordion {
                position: relative;
                .icon {
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  #{$rdirection}: 0;
                  cursor: pointer;
                  background: none;
                  top: -4px;
                  @media #{$cr19-large-up} {
                    width: 16px;
                    top: 1px;
                  }
                  &::before,
                  &::after {
                    @include translate(-50%, -50%);
                    content: '';
                    background: $color-black;
                    display: block;
                    height: 2px;
                    width: 16px;
                    position: absolute;
                    #{$ldirection}: 50%;
                    top: 50%;
                  }
                  &::after {
                    width: 2px;
                    height: 16px;
                  }
                  &.icon--minus::after {
                    visibility: hidden;
                  }
                }
                .checkout__subtitle {
                  padding: 0;
                  margin: 0;
                }
              }
              .sms__accordion {
                position: relative;
                .icon {
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  #{$rdirection}: 0;
                  cursor: pointer;
                  background: none;
                  top: -3px;
                  @media #{$cr19-large-up} {
                    top: 0;
                  }
                  &::before,
                  &::after {
                    @include translate(-50%, -50%);
                    content: '';
                    background: $color-black;
                    display: block;
                    height: 2px;
                    width: 16px;
                    position: absolute;
                    #{$ldirection}: 50%;
                    top: 50%;
                  }
                  &::after {
                    width: 2px;
                    height: 16px;
                  }
                  &.icon--minus::after {
                    visibility: hidden;
                  }
                }
                .checkout__subtitle {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
      #shipping-panel {
        h3.checkout__subtitle {
          margin-top: 0;
          width: calc(100% - 24px);
        }
      }
    }
  }
}
.email_promotions {
  .email-promotion-link {
    display: none;
  }
}
