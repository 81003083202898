.clinical-reality {
  &__recommended-products {
    &--container {
      @include start-breakpoint($large-min) {
        padding: 14px;
      }
      a.product-add-to-bag {
        margin-top: 20px;
      }
    }
  }
  &__results-intro--description {
    .clinical-reality__button-options {
      @media #{$medium-up} {
        display: flex;
      }
    }
  }
  &__recommended-products--description {
    @media #{$medium-up} {
      width: 43%;
    }
  }
  &--button {
    @include start-breakpoint($medium-max) {
      padding: 16px 30px;
    }
  }
  &__article-container--wink {
    @include start-breakpoint($large-min) {
      padding-#{$ldirection}: 10px;
      padding-#{$rdirection}: 10px;
    }
  }
  &__article--left {
    @include start-breakpoint($large-min) {
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 10px;
    }
    padding-#{$ldirection}: 14px;
    padding-#{$rdirection}: 14px;
  }
  &__article--right {
    @include start-breakpoint($large-min) {
      margin-#{$ldirection}: 0;
      padding-#{$ldirection}: 10px;
    }
    padding-#{$ldirection}: 14px;
    padding-#{$rdirection}: 14px;
  }
}

.clinique-reality-overlay {
  iframe {
    height: 380px;
  }
}

.ccr-flexslider {
  .clinical-reality__mpp--carousel {
    bottom: 0;
  }
  .flex-viewport {
    @include start-breakpoint($medium-m) {
      max-height: 665px;
    }
    max-height: 560px;
  }
}

.clinical-reality {
  &__recommended-products {
    &--container {
      a.product-add-to-bag {
        @include start-breakpoint($medium-m) {
          min-width: 160px;
          line-height: 1.2;
          padding: 13px;
        }
      }
    }
  }
  &__save-button-options {
    @include start-breakpoint($medium-m) {
      float: none;
      padding-top: 15px;
    }
  }
}

.clinical-reality__modules--recommended-products {
  .clinical-reality__recommended-products {
    &--description {
      @include start-breakpoint($medium-min) {
        width: 60%;
      }
    }
  }
  .clinical-reality {
    &__recommended-products--carousel {
      top: 39%;
    }
  }
}

#clinical-reality {
  .clinical-reality {
    &--button {
      @include start-breakpoint($medium-min) {
        padding: 16px 10px;
      }
    }
  }
}

.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
}

.ccr-flexslider {
  .flex-viewport {
    @include start-breakpoint($medium-min) {
      max-height: 100%;
    }
    max-height: 580px;
  }
}

.clinical-reality-recommended-products-v2.outer-wrap.clinical-reality {
  padding: 0 20px !important;
}
