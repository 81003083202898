.spc_enabled {
  .enhanced-delivery-page,
  &.enhanced-delivery-page {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column {
            &.left {
              #shipping-panel {
                .gift-options {
                  border-bottom: 1px solid $color-grey;
                  border-top: 1px solid $color-grey;
                  display: block;
                  margin-bottom: 14px;
                  padding: 9px 0 4px;
                  position: relative;
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 5px 16px 1px 18px);
                    margin-bottom: 18px;
                  }
                  .giftwrap {
                    margin-bottom: 0;
                    &.form-container {
                      display: none;
                    }
                    .sub-section {
                      padding: 0 16px;
                      @media #{$cr19-large-up} {
                        padding: 0 0 16px;
                      }
                    }
                    .card-message {
                      label {
                        bottom: 70px;
                        padding-#{$ldirection}: 0 !important;
                        position: relative;
                      }
                    }
                  }
                  .giftmessage {
                    margin: 0 0 10px;
                    .checkout__subtitle {
                      font-size: 15px;
                      margin: 0;
                      padding: 10px 0 15px;
                    }
                    .gift-message-content {
                      padding: 0;
                      .form-item {
                        &.odd,
                        &.even {
                          float: none;
                          margin: 0 0 15px;
                          padding-top: 0;
                          width: 100%;
                          @media #{$cr19-large-up} {
                            float: $ldirection;
                            width: 50%;
                          }
                        }
                        @media #{$cr19-large-up} {
                          &.odd {
                            padding-#{$rdirection}: 7px;
                          }
                          &.even {
                            padding-#{$ldirection}: 7px;
                          }
                          &.card-message {
                            clear: both;
                          }
                        }
                        textarea {
                          @include appearance(none);
                          @include border-radius(0);
                          background-image: linear-gradient(transparent, transparent);
                          border: 1px solid $color-shade-dark-grey;
                          height: 134px;
                          padding: 17px 15px;
                          &:focus + label,
                          &.touched + label {
                            &::before {
                              @include transform(translateY(-25px) scale(0.8, 0.8));
                              color: $color-cl-light-black;
                            }
                          }
                        }
                        input {
                          @include appearance(none);
                          background: $color-white;
                          border: 1px solid $color-shade-dark-grey;
                          font-size: 16px;
                          height: 50px;
                          letter-spacing: normal;
                          padding-#{$ldirection}: 15px;
                          width: 100%;
                          &:focus + label,
                          &.touched + label {
                            &::before {
                              @include transform(translateY(-22px) scale(0.8, 0.8));
                              color: $color-cl-light-black;
                            }
                          }
                        }
                        label {
                          color: $color-black;
                          display: block;
                          font-family: $helvetica-regular-font-family;
                          font-size: 16px;
                          font-weight: 400;
                          letter-spacing: 1px;
                          line-height: 1.2;
                          opacity: 0.88;
                          padding-#{$ldirection}: 10px;
                        }
                      }
                    }
                  }
                  .giftwrap-switch {
                    .label-content {
                      color: $color-cl-light-black;
                      display: inline-block;
                      font-size: 12px;
                      @media #{$cr19-large-up} {
                        bottom: 34px;
                        padding: 0;
                      }
                    }
                    label {
                      display: inline-block;
                      margin: 14px 0 10px;
                      position: relative;
                      &::before {
                        @include border-radius(8px);
                        background: $cr19-border-light;
                        content: '';
                        display: block;
                        height: 16px;
                        position: absolute;
                        top: 0;
                        width: 40px;
                        #{$ldirection}: 0;
                      }
                      &::after {
                        @include border-radius(50%);
                        background: $cr19-bg-med;
                        content: '';
                        height: 20px;
                        position: absolute;
                        top: -2px;
                        transition: 0.4s;
                        width: 20px;
                        #{$ldirection}: 0;
                        -webkit-transition: 0.4s;
                      }
                      .label-content {
                        display: inline-block;
                        padding-#{$ldirection}: 56px;
                      }
                    }
                    input[type='checkbox'] {
                      cursor: pointer;
                      height: 18px;
                      opacity: 0;
                      position: absolute;
                      top: 5px;
                      width: 40px;
                      z-index: 2;
                      #{$ldirection}: 0;
                      &:checked {
                        & ~ label {
                          &::after {
                            position: absolute;
                            #{$ldirection}: 20px;
                          }
                          .gift-yes {
                            display: block;
                          }
                          .gift-no {
                            display: none;
                          }
                        }
                      }
                    }
                    .gift-yes {
                      display: none;
                    }
                  }
                  .icon {
                    cursor: pointer;
                    display: block;
                    height: 18px;
                    position: absolute;
                    top: 8px;
                    width: 18px;
                    #{$rdirection}: 18px;
                    @media #{$cr19-large-up} {
                      top: 10px;
                      #{$rdirection}: 14px;
                    }
                    &::after,
                    &::before {
                      @include translate(-50%, -50%);
                      background: $color-black;
                      content: '';
                      display: block;
                      height: 16px;
                      position: absolute;
                      top: 50%;
                      width: 2px;
                      #{$ldirection}: 50%;
                    }
                    &::before {
                      height: 2px;
                      width: 16px;
                    }
                  }
                  &.active {
                    .icon::after {
                      visibility: hidden;
                    }
                  }
                }
              }
            }
            &.right {
              #gift-options-display.column {
                margin-bottom: 0;
                width: 100%;
                .gift-wrap-left {
                  float: $ldirection;
                  width: 100px;
                }
                .checkout__subtitle,
                p {
                  font-size: 12px;
                }
                .checkout__subtitle {
                  color: $color-cl-light-black;
                  font-weight: bold;
                }
                p {
                  color: $cr19-text-grey;
                  margin: 4px 0 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
