.spc_enabled {
  .enhanced-delivery-page:not([class*='enhanced_signin_page']),
  &.enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column.left {
            .loyalty-program {
              border-bottom: 1px solid $color-grey;
              border-top: 1px solid $color-grey;
              margin: 0 0 14px;
              padding: 9px 16px;
              @media #{$cr19-large-up} {
                padding: 6px 15px;
                margin: 0;
              }
              .form_element {
                float: none;
                margin-#{$rdirection}: 0;
                .field {
                  margin-top: 3px;
                }
              }
              input {
                height: 16px;
                width: 16px;
              }
              label {
                width: auto;
              }
              .section-loyalty-promotions {
                margin-bottom: 0 !important;
              }
              .loyalty_join {
                margin: 15px 0 0;
                .label-content {
                  position: relative;
                  display: block;
                }
              }
              .loyalty-join__subtitle {
                padding: 0;
                margin: 0;
              }
            }
            .loyalty__accordion {
              position: relative;
              .icon {
                width: 16px;
                height: 24px;
                position: absolute;
                #{$rdirection}: 0;
                cursor: pointer;
                background: none;
                top: -4px;
                @media #{$cr19-large-up} {
                  top: 1px;
                }
                &::before,
                &::after {
                  @include translate(-50%, -50%);
                  content: '';
                  background: $color-black;
                  display: block;
                  height: 2px;
                  width: 16px;
                  position: absolute;
                  #{$ldirection}: 50%;
                  top: 50%;
                }
                &::after {
                  width: 2px;
                  height: 16px;
                }
                &.icon--minus::after {
                  visibility: hidden;
                }
              }
            }
            .guarantee-panel {
              border-top: 1px solid $color-grey;
              border-bottom: 1px solid $color-grey;
              margin-top: 0 !important;
              margin-bottom: 14px;
              padding-bottom: 16px;
              .guarantee-panel__title {
                display: block;
                font-size: 15px;
                font-weight: 700;
                font-family: $base-bold-font-family;
                padding: 16px 16px 1px;
                text-transform: uppercase;
                @media #{$cr19-large-up} {
                  font-size: 22px;
                }
              }
              .content {
                padding: 0 16px;
                p {
                  margin: 0;
                }
              }
            }
            #review-panel {
              .payment-method-container {
                .create-loyalty-account-panel {
                  @include swap_direction(padding, 5px 7px 5px 16px);
                  border-bottom: 1px solid $color-grey;
                  border-top: 1px solid $color-grey;
                  display: block;
                  margin: 20px 0 16px;
                  position: relative;
                  .checkout__subtitle {
                    text-align: $ldirection;
                    margin: 0;
                    padding: 5px 0;
                    @media #{$cr19-large-up} {
                      padding: 0;
                    }
                  }
                  #create_loyalty_account_container {
                    .form-item {
                      margin-top: 20px;
                      label {
                        position: relative;
                        #{$ldirection}: 10px;
                      }
                    }
                    input[type='checkbox'] {
                      @include appearance(none);
                      border: 1px solid;
                      float: $ldirection;
                      width: 18px;
                      height: 18px;
                      margin-#{$ldirection}: 1px;
                      &:checked {
                        background-color: $color-white;
                        &::before {
                          @include rotate(45deg);
                          border: solid $color-black;
                          border-width: 0 2px 2px 0;
                          content: '';
                          display: block;
                          height: 11px;
                          position: relative;
                          top: -2px;
                          #{$ldirection}: -2px;
                          width: 4px;
                        }
                      }
                    }
                  }
                  .icon {
                    cursor: pointer;
                    display: block;
                    height: 18px;
                    position: absolute;
                    #{$rdirection}: 18px;
                    top: 11px;
                    width: 18px;
                    @media #{$cr19-large-up} {
                      top: 9px;
                    }
                    &::after,
                    &::before {
                      @include translate(-50%, -50%);
                      background: $color-black;
                      content: '';
                      display: block;
                      height: 16px;
                      #{$ldirection}: 50%;
                      position: absolute;
                      top: 50%;
                      width: 2px;
                    }
                    &::before {
                      height: 2px;
                      width: 16px;
                    }
                    &.show-icon {
                      &::after {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
