.spc_enabled {
  &.enhanced-delivery-page:not([class*='enhanced_signin_page']),
  .enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column {
            &.left {
              #review-panel {
                padding-bottom: 55px;
                .checkout__panel-title {
                  display: none;
                }
                @media #{$cr19-large-up} {
                  padding-bottom: 0;
                  header {
                    border: 0;
                  }
                  .content {
                    padding: 0;
                  }
                }
                .billing-address-content {
                  display: none;
                }
                .order_terms {
                  line-height: 1.45;
                  padding: 0 16px;
                  label {
                    @include swap_direction(padding, 0 0 0 35px);
                    color: $color-cl-light-black;
                    float: none;
                    font-family: $helvetica-regular-font-family;
                    position: relative;
                    #{$ldirection}: 0;
                    width: auto;
                    a {
                      color: $color-cl-light-black;
                      text-decoration: underline;
                    }
                  }
                }
                .payment-method-container {
                  border: 0;
                  .legal_frame {
                    position: relative;
                    top: 1px;
                    padding: 0 16px;
                    @media #{$cr19-large-up} {
                      padding: 0;
                    }
                    .legal_iframe {
                      @include swap_direction(padding, 0 1px 0 14px);
                      border: 1px solid $color-gray-dusty;
                      opacity: 0.5;
                      .pg_wrapper {
                        background-color: $cr19-bg-light;
                        margin: 0;
                        color: $color-gray-dusty;
                        padding-#{$ldirection}: 14px;
                        font-family: $helvetica-bold-font-family;
                        line-height: 1.4;
                        letter-spacing: 0.25px;
                      }
                    }
                  }
                  label {
                    margin: 0;
                  }
                  .order_terms {
                    input[type='checkbox'] {
                      margin: 1px 0 0;
                    }
                  }
                  input[type='checkbox'] {
                    @include appearance(none);
                    width: 18px;
                    height: 18px;
                    border: 1px solid;
                    margin: 3px 0 0;
                    position: relative;
                    vertical-align: middle;
                    &:focus {
                      outline: 0;
                    }
                    &:checked {
                      background-color: $color-white;
                      &::before {
                        @include transform(rotate(45deg));
                        @include swap_direction(border-width, 0 2px 2px 0);
                        content: '';
                        position: relative;
                        display: block;
                        #{$ldirection}: 5px;
                        top: 0;
                        width: 4px;
                        height: 11px;
                      }
                    }
                    &.error {
                      border: 1px solid $color-notice-pink;
                    }
                  }
                  #checkout_complete {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                  }
                  .checkout__title {
                    font-weight: bold;
                    font-size: 36px;
                    line-height: 0.9;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 10px 0 0;
                  }
                  .checkout__subtitle {
                    font-size: 15px !important;
                    line-height: 1.4;
                    text-align: center;
                    letter-spacing: 0.25px;
                    text-transform: uppercase;
                    color: $color-black-88opacity;
                    margin: 16px 0 20px;
                    @media #{$cr19-large-up} {
                      font-weight: bold;
                      font-size: 22px !important;
                    }
                  }
                  .select-address {
                    &.form-container {
                      padding: 0 20px;
                      @media #{$cr19-large-up} {
                        padding: 0;
                      }
                    }
                  }
                  .payment-description {
                    color: $color-gray-dusty;
                    height: auto;
                    float: none;
                    margin: 15px 16px 20px;
                    border: 1px solid $color-gray-dusty;
                    font-size: 12px;
                    line-height: 1.7;
                    letter-spacing: 0.25px;
                    padding: 14px;
                    @media #{$cr19-large-up} {
                      margin: 20px 0 0;
                    }
                  }
                  .installopt-PAYMENT_OPTION_CARDLINK {
                    border-bottom: 1px solid $color-grey;
                    margin: 10px 0 20px;
                    padding: 0 16px 8px;
                    @media #{$cr19-large-up} {
                      padding-#{$ldirection}: 0;
                      padding-#{$rdirection}: 0;
                    }
                    &::after {
                      content: '';
                      border: 5px solid transparent;
                      border-top-color: $color-gray-dusty;
                      position: relative;
                      #{$rdirection}: 33px;
                      top: 8px;
                      pointer-events: none;
                      clear: both;
                      display: initial;
                    }
                    .field {
                      @include appearance(none);
                      @include border-radius(0);
                      background-image: none;
                      border: 1px solid $color-gray-dusty;
                      color: $color-gray-dusty;
                      font-size: 16px;
                      height: 54px;
                      padding-bottom: 8px;
                      width: 100%;
                    }
                  }
                  .field-container {
                    float: none;
                    &.payment-type {
                      margin-top: 37px;
                      @media #{$cr19-large-up} {
                        margin: 60px 1px 0;
                      }
                    }
                    .cc-header {
                      margin: 0;
                      padding: 22px 51px 15px;
                      font-weight: bold;
                      font-size: 14px;
                      font-family: $helvetica-bold-font-family;
                      line-height: get-line-height(14, 16);
                      letter-spacing: 0.1px;
                      text-transform: uppercase;
                      border-top: 1px solid $cr19-text-grey;
                    }
                    .payment-type {
                      @include display-flex;
                      min-height: 54px;
                      width: 100%;
                      border-top: 1px solid $cr19-text-grey;
                      clear: both;
                      padding: 0;
                      position: relative;
                      float: none;
                      &:last-child {
                        border-bottom: 1px solid $cr19-text-grey;
                      }
                      input[type='radio'].field {
                        @include border-radius(50%);
                        margin: auto 16px !important;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $color-dim-gray;
                        #{$ldirection}: 0;
                        position: absolute;
                        top: 16px;
                        &:checked {
                          &::before {
                            @include border-radius(50%);
                            content: '';
                            display: block;
                            width: 14px;
                            height: 14px;
                            margin: 0 auto;
                            background: $color-black;
                            position: absolute;
                            bottom: 0;
                            #{$ldirection}: 2px;
                            top: 2px;
                          }
                        }
                      }
                      #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_sofort_label {
                        .img_section {
                          border: 1px solid $cr19-border-light;
                          margin-#{$rdirection}: 17px;
                          padding-bottom: 5px;
                          width: 40px;
                        }
                      }
                      #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label {
                        .img_section {
                          padding-top: 10px;
                        }
                      }
                      #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_paypal_label {
                        padding-top: 0;
                      }
                      #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mybank_label {
                        .image_section {
                          height: auto;
                          width: 46px;
                        }
                      }
                      #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_electron_label {
                        .img_section {
                          margin-#{$rdirection}: 18px;
                          width: 38px;
                        }
                      }
                      label {
                        @include display-flex;
                        @include swap_direction(padding, 0 0 0 52px);
                        align-items: center;
                        margin: auto 0;
                        width: 100%;
                        .text-label {
                          display: inline-block;
                          font-family: $helvetica-bold-font-family;
                          font-weight: bold;
                          font-size: 14px;
                          letter-spacing: 0.1px;
                          line-height: 1.14;
                          margin: 0;
                          padding: 18px 0 14px;
                          text-transform: uppercase;
                          width: 100%;
                        }
                        .image_section {
                          @include swap_direction(margin, auto 14px auto auto);
                          height: 24px;
                          width: auto;
                        }
                        .cc-logos {
                          margin-#{$rdirection}: 14px;
                          white-space: nowrap;
                        }
                        .img_section {
                          @include swap_direction(margin, auto 14px auto auto);
                          width: 46px;
                        }
                        .image_section_cc {
                          width: 46px;
                        }
                        .img_section_pp {
                          width: auto;
                        }
                      }
                      &.payment-type-cc {
                        border-top-color: $cr19-border-light;
                        width: 100%;
                        float: $ldirection;
                        clear: none;
                        &:first-child {
                          border-top-color: $cr19-text-grey;
                          &:last-child {
                            border-bottom-color: $cr19-border-light;
                            border-top-color: $cr19-border-light;
                          }
                        }
                        &.two-column {
                          width: 50%;
                          &.odd-column {
                            border-#{$rdirection}: 1px solid $cr19-border-light;
                          }
                          label {
                            padding-#{$ldirection}: 42px;
                            .text-label {
                              font-size: 10px;
                              line-height: 1.6;
                              padding: 18px 0;
                              width: 65%;
                              word-break: break-word;
                              @media #{$cr19-large-up} {
                                width: 75%;
                              }
                            }
                          }
                          input[type='radio'].field {
                            margin-#{$rdirection}: 8px !important;
                          }
                        }
                      }
                    }
                  }
                }
                #continue-btn {
                  @include box-shadow(0 -4px 8px $color-black-2opacity);
                  z-index: 20;
                  position: fixed;
                  bottom: 0;
                  height: auto;
                  #{$ldirection}: 0;
                  #{$rdirection}: auto;
                  display: block;
                  width: 100% !important;
                  margin-top: 50px;
                  background: $color-white;
                  padding: 8px 9px;
                  @media #{$cr19-large-up} {
                    @include box-shadow(none);
                    position: static;
                    padding: 0;
                  }
                  .form-submit {
                    @include appearance(none);
                    @include border-radius(0);
                    height: 48px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 17px;
                    margin-top: 0;
                    line-height: 1.4;
                    width: 100%;
                    @media #{$cr19-large-up} {
                      width: 348px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
