// Profile preference page
.wrapper,
#myaccount-wrapper {
  .social-info {
    .social-info__connect {
      display: block;
      .fb-disclaimer-container {
        margin-top: 10px;
      }
    }
  }
}

#registration {
  .social-info__content {
    .tooltip {
      .tooltiptext-over {
        text-align: justify;
        overflow-y: scroll;
        height: 130px;
        padding: 10px;
      }
    }
    .fb-overlay-social-info {
      display: inline-block;
      vertical-align: top;
    }
  }
}
// Signin page
.social-login {
  .social-login__container {
    .tooltip {
      .tooltiptext-over {
        text-align: justify;
        overflow: scroll;
        height: 130px;
        padding: 10px;
      }
    }
  }
}

#signin {
  #signin-block {
    .social-login {
      .social-login__email-opt-in label,
      .fb-disclaimer {
        color: $color-btn-dark-gray;
      }
      .social-login__email-opt-in input[type='checkbox'] {
        width: 55px;
        height: 15px;
        @media #{$large-up} {
          width: auto;
          height: auto;
        }
      }
    }
  }
}
// checkout signinpage
.spc_enabled {
  #main.single {
    .checkout {
      .sign-in-panel {
        input[type='checkbox'].facebook_email_optin_input {
          width: 60px;
          height: 19px;
          @media #{$large-up} {
            width: auto;
            height: auto;
          }
        }
        .fb-disclaimer-container input[type='checkbox'] {
          @media #{$medium-up} {
            width: 15px;
            height: 13px;
          }
        }
        .social-login {
          .social-login__email-opt-in {
            .social-login__email-opt-in-label {
              @media #{$medium-up} {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}

// Order confirmation page
#confirm {
  .checkout-confirmation-page {
    .social-login {
      .social-login__email-opt-in label {
        color: $color-btn-dark-gray;
      }
      .social-login__email-opt-in input[type='checkbox'] {
        margin-right: 0;
        width: 52px;
        height: 17px;
        @media #{$large-up} {
          width: auto;
          height: auto;
          margin-right: 7px;
        }
      }
    }
  }
}
// Gnav
.elc-user-state-logged-in {
  .gnav-my-account-v1 {
    .social-login.gnav {
      .social-login__container {
        display: none;
      }
    }
  }
}

.signin_container {
  .gnav {
    .social-login__container {
      .tooltip {
        display: block;
        width: 60%;
      }
    }
  }
}
// Book appointment page
#appointment-book-container.appt-book {
  .appt-book-section-content {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          .social-login {
            .fb-disclaimer-container input,
            .social-login__email-opt-in input {
              width: auto;
              height: auto;
              padding: 7px;
              margin: 13px 0 0 0;
              @media #{$medium-up} {
                width: 20px;
                height: 20px;
              }
            }
            .fb-disclaimer {
              @media #{$medium-up} {
                margin-left: 9px;
              }
            }
            .social-login__opt-in-label {
              color: $color-btn-dark-gray;
              margin-left: 10px;
              @media #{$medium-up} {
                margin-left: 9px;
              }
            }
          }
        }
      }
    }
  }
}
