.cl-spp-slider-formatter-v1 {
  span.slider-formatter-before-text {
    @if $cr19 == true {
      background: $cr19-bg-black;
    } @else {
      background: #5dba98;
    }
    border-radius: 50%;
    display: table-cell;
    color: #ffffff;
    font-size: 14px;
    height: 55px;
    line-height: 55px;
    -moz-border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    webkit-border-radius: 50%;
    width: 55px;
  }
}
