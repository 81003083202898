.spc_enabled {
  &.enhanced-delivery-page:not([class*='enhanced_signin_page']),
  .enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #footer {
      margin-top: 0;
      border-top: 0;
    }
    .sitewide-footer-formatter__bottom {
      margin-top: 0;
    }
    #main.single {
      padding-bottom: 0;
      .panel {
        header {
          margin: 0;
          border: 0;
        }
      }
      .responsive-container {
        .pg_wrapper {
          max-width: $cr19-max-width-medium;
          width: auto;
          position: relative;
          @media #{$cr19-large-up} {
            @include box-shadow(10px 0 5px -11px $color-light-grey);
            margin: 0 auto;
          }
          .checkout__body--content {
            display: block;
            @media #{$cr19-large-up} {
              @include display-flex;
              overflow: hidden;
            }
            .messages-container {
              [class*='error_offer_criteria_met'] {
                display: none;
              }
              &.messages.error {
                background: none;
                #error_invalid_offer_code {
                  display: none;
                }
                .single-message {
                  border: 1px solid $color-dark-orange;
                  color: $color-dark-orange;
                  font-size: 15px;
                  margin-top: 25px;
                  padding: 12px;
                  &:first-child {
                    margin-top: 12px;
                  }
                }
              }
            }
          }
          .review_header {
            &.title_center {
              display: none;
            }
          }
          .column {
            #checkout_shipping_panel {
              .city-state-zip {
                .city,
                .postal-code {
                  .invalid_marker {
                    #{$rdirection}: 20px;
                  }
                }
              }
            }
            @media #{$cr19-large-up} {
              &.right {
                @include box-shadow(1px 2px 10px $color-light-grey);
                width: 380px;
                background: $cr19-bg-light;
                #{$rdirection}: 0;
              }
            }
            &.left {
              @media #{$cr19-large-up} {
                float: $ldirection;
                margin: 18px 0 75px;
                padding: 0 24px;
                width: calc(100% - 380px);
              }
              .attention {
                display: block !important;
                font-size: 12px;
                line-height: 1.4;
                letter-spacing: 0.25px;
                color: $color-cl-light-black;
                border: 1px solid;
                padding: 13px 15px;
                margin-top: 35px;
              }
              .wrapper {
                max-width: 562px;
                margin: 0 auto;
                @media #{$cr19-large-up} {
                  padding: 0;
                }
              }
              .sign-in-panel {
                display: block;
                padding: 0 0 32px;
                @media #{$cr19-large-up} {
                  margin: 0 0 42px;
                  padding: 0;
                  .hidden_border {
                    border: 0;
                  }
                }
                .signin-panel__title {
                  display: none;
                }
                .content {
                  letter-spacing: 0.5px;
                  font-size: 16px;
                  background: $cr19-bg-light;
                  margin: 0 16px;
                  height: 54px;
                  padding: 16px;
                  color: $color-black-4opacity;
                  @media #{$cr19-large-up} {
                    margin-#{$ldirection}: 0;
                    margin-#{$rdirection}: 0;
                  }
                }
              }
              .checkout-additional-info,
              .delivery_text {
                background: $color-black-opacity;
                border: 1px solid $color-shade-dark-grey;
                font-family: $helvetica-regular-font-family;
                font-size: 12px;
                letter-spacing: 0.25px;
                line-height: 1.67;
                margin: 0 16px 24px;
                padding: 13px 14.5px;
                @media #{$cr19-large-up} {
                  background: $color-black-02opacity;
                  margin-#{$ldirection}: 0;
                  margin-#{$rdirection}: 0;
                }
              }
              .content {
                &.two-column-form {
                  margin-top: 20px;
                  padding: 0;
                  @media #{$cr19-large-up} {
                    margin-top: 30px;
                  }
                }
                .checkout__subtitle {
                  font-weight: 700;
                  font-size: 15px;
                  text-transform: uppercase;
                  font-family: $helvetica-bold-font-family;
                  padding: 0 17px;
                  margin: 22px 0 5px;
                  @media #{$cr19-large-up} {
                    font-size: 22px;
                    padding: 0;
                  }
                }
                .left {
                  display: none;
                }
              }
              .new-address-container {
                .delivery_instructions {
                  label {
                    padding-#{$ldirection}: 0 !important;
                  }
                }
                .field-container {
                  .form-item {
                    &.address4,
                    &.company-container,
                    &.rm-gutter {
                      padding: 0;
                    }
                    &.address4-container {
                      padding: 22px 0 0;
                    }
                  }
                  &.city-state-zip {
                    .state::after {
                      #{$rdirection}: 33px;
                      border: 5px solid transparent;
                      border-top-color: $color-black;
                      content: '';
                      pointer-events: none;
                      position: absolute;
                      top: 65%;
                    }
                  }
                }
              }
              .billing-address-form-fields {
                display: block;
                margin-top: 50px;
                padding-bottom: 20px;
                @media #{$cr19-large-up} {
                  padding-bottom: 40px;
                }
                &.btos {
                  .field-container--grid {
                    &.country {
                      border: 0;
                      .form-item.select {
                        padding: 0;
                        label {
                          display: none;
                        }
                      }
                    }
                  }
                  .bill_country {
                    .form-item.country-id {
                      label {
                        display: none;
                      }
                      &.select::after {
                        top: 65%;
                      }
                    }
                  }
                }
                .checkout__subtitle {
                  padding-#{$ldirection}: 0;
                }
                .delivery_instructions {
                  label {
                    padding-#{$ldirection}: 0 !important;
                  }
                }
                .form-item {
                  float: none;
                  margin: 0 !important;
                  padding: 15px 0 0;
                  width: 100%;
                  &.country-id {
                    &.select::after {
                      #{$rdirection}: 33px;
                      border: 5px solid transparent;
                      border-top-color: $color-black;
                      content: '';
                      pointer-events: none;
                      position: absolute;
                      top: 38px;
                    }
                  }
                }
                .field-container {
                  .form-item {
                    &.company-container {
                      padding: 0;
                    }
                    &.country,
                    &.bill_country {
                      border: 0;
                      margin-top: 22px;
                      .country-id {
                        padding: 14px;
                        &.select {
                          padding: 0;
                          label {
                            display: none;
                          }
                        }
                      }
                    }
                    &.delivery_instructions {
                      padding-bottom: 22px;
                    }
                  }
                }
              }
              .new-address-container,
              .edit-address-container,
              .new-address,
              .billing-address-form-fields {
                display: block;
                padding: 0 17px;
                @media #{$cr19-large-up} {
                  padding: 0;
                }
                &.add {
                  display: block;
                }
                .checkout__subtitle {
                  margin-bottom: 0;
                }
                .field-container,
                .field-container--grid {
                  &.city-state-zip {
                    .form-item {
                      &:first-child {
                        padding-top: 22px;
                      }
                      .city,
                      &.with-field-lookup {
                        padding-top: 0;
                      }
                    }
                  }
                  &.country {
                    height: 54px;
                    padding: 0;
                    color: $color-black-88opacity;
                    margin-top: 22px;
                    background-color: $cr19-bg-light;
                    border: 1px solid $color-shade-dark-grey;
                    .form-item.select {
                      padding-top: 16px;
                    }
                    .label-content {
                      color: $cr19-text-grey;
                      position: relative;
                      #{$ldirection}: 5px;
                    }
                  }
                  .phone_label_container,
                  &.google-autocomplete-field {
                    display: none;
                  }
                  .form-item {
                    @include appearance(none);
                    float: none;
                    width: 100%;
                    padding: 22px 0 0;
                    margin: 0 !important;
                    &.title {
                      &.select::after {
                        content: '';
                        position: absolute;
                        border: 5px solid transparent;
                        border-top-color: $color-black;
                        top: 45px;
                        #{$rdirection}: 33px;
                        pointer-events: none;
                      }
                    }
                    &.country-id {
                      &.select::after {
                        top: 50%;
                      }
                      &.ship-country {
                        &.select::after {
                          display: none;
                        }
                      }
                    }
                    &.delivery_instructions {
                      max-width: 560px;
                      textarea {
                        &.adaptive-label-mode + label {
                          &::before {
                            @include transform(translateY(-24px) scale(0.8, 0.8));
                            color: $color-cl-light-black;
                            padding: 0 5px;
                          }
                        }
                        &:focus + label {
                          &::before {
                            @include transform(translateY(-24px) scale(0.8, 0.8));
                            color: $color-cl-light-black;
                            padding: 0 5px;
                          }
                        }
                      }
                    }
                    input,
                    select {
                      &.adaptive-label-mode + label {
                        &::before {
                          @include transform(translateY(-28px) scale(0.8, 0.8));
                          color: $color-cl-light-black;
                          padding: 0 5px;
                        }
                      }
                      &:required {
                        &:invalid {
                          border-color: $color-red;
                          &:empty,
                          &[value=''],
                          &:not(:checked) {
                            border-color: $color-shade-dark-grey;
                            &.error,
                            &.checkedEmpty,
                            &.touched {
                              border-color: $color-red;
                            }
                            &.touched + label {
                              &::before {
                                background: $color-white;
                                color: $color-monza-light;
                              }
                            }
                            &.touched:focus + label {
                              &::before {
                                color: $color-cl-light-black;
                              }
                            }
                            &.touched.adaptive-placeholder-mode + label {
                              &::before {
                                transform: none;
                              }
                            }
                          }
                        }
                        &:valid + label {
                          &::before {
                            @include transform(translateY(-28px) scale(0.8, 0.8));
                            color: $color-cl-light-black;
                            padding: 0 5px;
                          }
                        }
                      }
                      &.touched + label {
                        &::before {
                          background: $color-white;
                        }
                      }
                      @include border-radius(0);
                      @include appearance(none);
                      @include swap_direction(padding, 17px 20px 13px 15px);
                      font-size: 16px;
                      background: $color-white;
                      height: 54px;
                      letter-spacing: normal;
                      line-height: normal;
                      width: 100%;
                      border: 1px solid $color-shade-dark-grey;
                      color: $color-shade-dark-grey;
                      &:disabled {
                        background-color: $cr19-bg-light;
                      }
                      &::after {
                        content: '';
                        border: 5px solid transparent;
                        border-top-color: $color-black;
                        pointer-events: none;
                        border-bottom: 0;
                      }
                    }
                    label {
                      padding-#{$ldirection}: 10px;
                      font-size: 16px;
                      line-height: 1.5;
                      color: $color-black-88opacity;
                      font-weight: normal;
                      font-family: $helvetica-regular-font-family;
                    }
                    .valid_marker,
                    .invalid_marker {
                      bottom: 8px;
                      height: 30px;
                      margin-bottom: 5px;
                      text-indent: -9999px;
                      #{$rdirection}: 15px;
                      width: 35px;
                      @media #{$cr19-large-up} {
                        #{$rdirection}: 15px;
                      }
                    }
                    .valid_marker {
                      background: url('/media/images/checkout/tick-icon_01.png') no-repeat 2px 7px;
                      background-size: 70%;
                    }
                    .invalid_marker {
                      background: url('/media/images/checkout/invalid-marker.png') no-repeat 0 3px;
                      background-size: 100%;
                      top: auto;
                      #{$rdirection}: 20px;
                    }
                    span.error {
                      display: none;
                    }
                    .field[type='text'],
                    .field[type='tel'] {
                      &.touched + label,
                      &:focus + label {
                        &::before {
                          @include transform(translateY(-28px) scale(0.8, 0.8));
                          color: $color-cl-light-black;
                          padding: 0 5px;
                        }
                      }
                    }
                  }
                }
              }
              .saved-billing-address {
                border-bottom: 1px solid $cr19-border-light;
                padding-bottom: 20px;
                margin: 50px 0 10px;
                .checkout-header {
                  border-bottom: 1px solid $cr19-border-light;
                  padding: 0 0 16px;
                  .checkout__subtitle {
                    margin-bottom: 0;
                  }
                }
                .billing-address {
                  color: $cr19-text;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  letter-spacing: 0.25px;
                  line-height: 1.4;
                  padding: 20px 17px 0;
                  position: relative;
                  a[href^='tel'] {
                    color: inherit;
                  }
                }
                #billing-panel-edit,
                .change_link {
                  background-image: url('/media/images/checkout/edit_icon.png');
                  background-size: contain;
                  float: $rdirection;
                  height: 24px;
                  margin-#{$rdirection}: 16px;
                  position: relative;
                  text-indent: -9999px;
                  top: 16px;
                  width: 24px;
                  z-index: 1;
                  &.cancel_link {
                    color: $color-cl-light-black;
                    font-size: 14px;
                    font-family: $helvetica-regular-font-family;
                    margin-#{$rdirection}: 16px;
                    position: relative;
                    top: 14px;
                    #{$rdirection}: 20px;
                    text-decoration: underline;
                    text-indent: 0;
                    width: auto;
                  }
                }
                & ~ .billing-address-form-fields {
                  margin-top: 0;
                  .checkout__subtitle {
                    display: none;
                  }
                }
              }
              .two-column-form {
                #checkout_shipping_panel {
                  .field-container {
                    .delivery_instructions {
                      label {
                        bottom: 34px;
                        position: relative;
                      }
                      textarea {
                        @include swap_direction(padding, 17px 20px 13px 17px);
                        @include border-radius(0);
                        @include box-shadow(none);
                        @include appearance(none);
                        background-image: linear-gradient(transparent, transparent);
                        border: 1px solid $color-shade-dark-grey;
                        color: $color-shade-dark-grey;
                        height: 94px;
                        font-size: 16px;
                        &:disabled {
                          background-color: $cr19-bg-light;
                        }
                      }
                    }
                  }
                }
                .click-and-collect {
                  .ship_group_0 {
                    margin-top: 24px;
                    label {
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      font-weight: 700;
                      margin: 0 4px;
                      text-transform: uppercase;
                    }
                  }
                  .personal-details {
                    position: relative;
                  }
                  .click-collect-title {
                    @include swap_direction(margin, 33px 40px 0 0);
                    font-family: $helvetica-bold-font-family;
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: 0.25px;
                    text-transform: uppercase;
                    @media #{$cr19-large-up} {
                      display: inline;
                      position: relative;
                    }
                    .info-link-message {
                      #{$rdirection}: 10px;
                      display: block;
                      height: 24px;
                      opacity: 0.4;
                      position: absolute;
                      top: 2px;
                      width: 24px;
                      @media #{$cr19-large-up} {
                        #{$rdirection}: -33px;
                      }
                    }
                  }
                }
              }
              .field-container--grid {
                .country-id {
                  p {
                    color: $color-gray-dusty;
                    position: relative;
                    #{$ldirection}: 16px;
                    bottom: 7px;
                    font-size: 16px;
                    line-height: 1.2;
                    font-weight: 400;
                    letter-spacing: 1px;
                  }
                }
              }
              .corporate-invoice {
                padding: 0 17px;
                @media #{$cr19-large-up} {
                  padding: 0;
                }
              }
              .edit-address-container {
                &.delivery-form-container {
                  .edit-shipping-address {
                    .address {
                      .field-container {
                        .address4 {
                          padding: 0;
                        }
                        .address4-container {
                          padding: 22px 0 0;
                        }
                      }
                      .default-shipping {
                        &.checkbox {
                          display: block;
                          padding-bottom: 20px;
                          & ~ .default_ship_container {
                            display: none;
                          }
                        }
                      }
                      .bill-to-shipping {
                        display: none;
                      }
                    }
                  }
                }
              }
              .bill-to-shipping {
                margin: 16px 0 !important;
                min-width: 100%;
                padding: 0;
                @media #{$cr19-large-up} {
                  margin: 24px 0 !important;
                }
                label {
                  line-height: 1.43;
                }
              }
              .default-shipping,
              .pc_email_promo_container,
              .pc_loyalty_join_container,
              .corporate_invoice,
              .order_terms,
              .bill-to-shipping {
                font-size: 14px;
                label {
                  @include swap_direction(padding, 1px 0 0 30px);
                  display: block !important;
                  font-family: $helvetica-light-font-family;
                }
                input[type='checkbox'] {
                  @include border-radius(0);
                  @include appearance(none);
                  float: $ldirection;
                  width: 18px;
                  height: 18px !important;
                  border: 1px solid $cr19-bg-med;
                  margin-#{$ldirection}: 0;
                  padding: 0;
                  &:checked {
                    background-color: $color-white;
                    &::before {
                      @include rotate(45deg);
                      content: '';
                      position: relative;
                      display: block;
                      #{$ldirection}: 5px;
                      top: 0;
                      width: 4px;
                      height: 11px;
                      border: solid $color-black;
                      border-width: 0 2px 2px 0;
                    }
                  }
                }
              }
              .pc_email_promo_container {
                font-size: 12px;
                margin: 16px 0 0;
                &:first-of-type {
                  margin: 0 0 10px;
                }
                p {
                  margin: 0;
                }
                input[type='checkbox'] {
                  @include appearance(none);
                  border-width: 1px;
                  &:checked {
                    &::before {
                      top: 0;
                      #{$ldirection}: 5px;
                    }
                  }
                }
              }
              .sms-mobile {
                .sms-mobile-phone {
                  input {
                    &.js-label-mode + label {
                      &::before {
                        @include transform(translateY(-20px) scale(0.8, 0.8));
                        padding: 0 5px;
                      }
                    }
                  }
                }
              }
              .default-shipping {
                margin-top: 25px !important;
                padding-top: 0;
                label {
                  font-size: 14px;
                  line-height: 1.43;
                  padding-top: 0;
                }
              }
              #shipping-panel {
                .address-title {
                  font-weight: normal;
                }
                .shipping-panel__title {
                  margin: 0 0 4px;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 36px !important;
                  line-height: 0.9;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                  width: 100%;
                  text-align: center;
                  font-family: $helvetica-bold-font-family;
                  padding: 5px 20px 0;
                  @media #{$cr19-large-up} {
                    margin: 0 0 12px;
                    padding: 0;
                  }
                }
                .form-item {
                  &.postal_code,
                  &.phone-2,
                  &.phone-1 {
                    .invalid_marker {
                      bottom: 8px;
                      top: auto;
                    }
                    &.error-message {
                      .field[type='tel'],
                      .field[type='text'] {
                        border-color: $color-red;
                      }
                      .error ~ .invalid_marker {
                        display: none;
                      }
                      .valid_marker,
                      .invalid_marker {
                        bottom: 29px;
                      }
                      span.error {
                        color: $color-red;
                        display: block;
                        margin: 3px 0;
                      }
                    }
                  }
                  &.phone-1 {
                    &.error-message {
                      .valid_marker {
                        bottom: 22px;
                      }
                      .invalid_marker {
                        bottom: 25px;
                      }
                    }
                  }
                  &.state {
                    &.select {
                      label {
                        display: none;
                      }
                    }
                    &.select::after {
                      #{$rdirection}: 33px;
                      border: 5px solid transparent;
                      border-top-color: $color-black;
                      content: '';
                      pointer-events: none;
                      position: absolute;
                      top: 65%;
                    }
                  }
                }
                #checkout_shipping_panel {
                  .corporate-invoice {
                    .corporate_options {
                      .corporate-vat-lookup-instructions {
                        padding-top: 15px;
                      }
                      .form-item {
                        @include appearance(none);
                        margin: 0;
                        padding: 22px 0 0;
                        &.invoice-form-disable {
                          input {
                            background-color: $cr19-bg-light;
                            pointer-events: none;
                          }
                        }
                        input {
                          &.adaptive-label-mode + label {
                            &::before {
                              @include transform(translateY(-28px) scale(0.8, 0.8));
                              color: $color-cl-light-black;
                              padding: 0 5px;
                            }
                          }
                          @include appearance(none);
                          @include swap_direction(padding, 17px 20px 13px 15px);
                          background: $color-white;
                          border: 1px solid $color-shade-dark-grey;
                          color: $color-shade-dark-grey;
                          font-size: 16px;
                          height: 54px;
                          letter-spacing: normal;
                          width: 100%;
                        }
                        label {
                          color: $color-black;
                          font-size: 16px;
                          font-weight: 400;
                          line-height: 1.5;
                          letter-spacing: 1px;
                          padding-#{$ldirection}: 10px;
                        }
                        .btn {
                          @include border-radius(0);
                          @include appearance(none);
                          background-color: $color-black;
                          color: $color-white;
                          font-style: normal;
                          font-weight: 700;
                          font-size: 17px;
                          line-height: 1.42;
                          padding: 0 15px;
                          width: 100%;
                          @media #{$cr19-large-up} {
                            display: block;
                            margin-#{$ldirection}: auto;
                            width: 59%;
                          }
                        }
                      }
                    }
                  }
                }
                &.ship-method-group-pack-active {
                  .bill-to-shipping {
                    display: none;
                  }
                  .packstation-address1 {
                    background-color: $cr19-bg-light;
                  }
                  .billing-address-form-fields {
                    padding-top: 20px;
                    @media #{$cr19-large-up} {
                      padding: 0;
                    }
                    .checkout__subtitle {
                      padding: 0;
                    }
                  }
                  @media #{$cr19-large-up} {
                    .info-link-content {
                      margin-top: 25px;
                    }
                  }
                }
                .edit-shipping-address {
                  &.guest-user-edit-shipping {
                    #continue-btn {
                      @include swap_direction(margin, 21px 0 20px auto);
                      @media #{$cr19-large-up} {
                        @include swap_direction(margin, 50px 0 50px auto);
                      }
                    }
                  }
                  #form--errors--address {
                    padding: 0;
                    width: 100%;
                    li {
                      @include border-radius(3px);
                      border: 1px solid $color-dark-orange;
                      color: $color-dark-orange;
                      font-size: 15px;
                      margin-bottom: 12px;
                      padding: 12px;
                      &:last-child {
                        margin: 0;
                      }
                    }
                  }
                }
                .delivery-form-container {
                  .required_mark {
                    display: none;
                  }
                  #continue-btn {
                    @include border-radius(0);
                    @include swap_direction(margin, 21px auto);
                    position: relative;
                    height: 48px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 1.42;
                    background: $color-black;
                    float: none;
                    color: $color-white;
                    text-transform: uppercase;
                    padding: 0;
                    text-align: center;
                    width: 100%;
                    @media #{$cr19-large-up} {
                      @include swap_direction(margin, 21px 0 21px auto);
                      display: block;
                      width: 348px !important;
                    }
                  }
                  .address-overlay-shipping {
                    h2 {
                      display: none;
                    }
                    fieldset.fs {
                      margin-top: 0;
                      .required-message,
                      .title_container .required_mark,
                      .address4_container,
                      .phone_label_container {
                        display: none;
                      }
                      .country_container {
                        height: 50px;
                        border: 1px solid $color-gray-dusty;
                        padding: 0;
                        color: $color-black;
                        opacity: 0.88;
                        margin: 15px 0 0;
                        background-color: $cr19-bg-light;
                        label {
                          padding: 15px 10px;
                          display: none;
                        }
                      }
                    }
                  }
                  .info-note {
                    display: none;
                  }
                  .full-width-wrap {
                    .info-link {
                      position: relative;
                      display: none;
                    }
                  }
                  fieldset {
                    .default_ship_container {
                      &.form_element {
                        padding-top: 16px;
                        @media #{$cr19-large-up} {
                          padding-top: 24px;
                        }
                        label {
                          font-size: 14px;
                          line-height: 1.43;
                          padding-top: 0;
                        }
                      }
                      input[type='checkbox'] {
                        @include swap_direction(margin, 0 0 0 1px);
                        @include appearance(none);
                        border-radius: 0;
                        float: $ldirection;
                        width: 18px;
                        height: 18px;
                        border: 1px solid;
                        padding: 0;
                        &:checked {
                          background-color: $color-white;
                        }
                      }
                      .label-content {
                        #{$ldirection}: 30px;
                        font-size: 14px;
                        letter-spacing: 0;
                        position: absolute;
                      }
                      label {
                        &::after {
                          @include rotate(45deg);
                          #{$ldirection}: 6px;
                          position: absolute;
                          width: 5px;
                          height: 11px;
                          border: solid $cr19-bg-med;
                          border-width: 0;
                          content: '';
                        }
                      }
                      input[type='checkbox']:checked ~ label::after {
                        @include swap_direction(border-width, 0 3px 3px 0);
                        display: block;
                      }
                    }
                    .address4_container {
                      display: none;
                    }
                    .form_element {
                      float: none;
                      width: 100%;
                      padding: 14px 0 0;
                      margin: 0;
                      input,
                      select {
                        @include appearance(none);
                        padding-#{$ldirection}: 15px;
                        font-size: 16px;
                        background: $color-white;
                        height: 50px;
                        letter-spacing: normal;
                        &:disabled {
                          background-color: $cr19-bg-light;
                        }
                      }
                      label,
                      em {
                        font-size: 16px;
                        line-height: 1.2;
                        color: $color-black-88opacity;
                        padding-#{$ldirection}: 10px;
                        font-weight: normal;
                        letter-spacing: 1px;
                        font-family: $helvetica-regular-font-family;
                      }
                    }
                    .title_container {
                      .label-content {
                        display: none;
                      }
                      &::after {
                        width: 1px;
                        content: '';
                        position: absolute;
                        border: 5px solid;
                        top: 59%;
                        #{$rdirection}: 20px;
                        pointer-events: none;
                        display: block;
                      }
                    }
                  }
                }
                .shipmethod-panel {
                  margin-bottom: 0;
                  margin-top: 20px;
                  @media #{$cr19-large-up} {
                    margin-top: 30px;
                  }
                  .checkout__subtitle {
                    @include swap_direction(padding, 0 0 14px 16px);
                    font-size: 22px;
                    font-family: $helvetica-regular-font-family;
                    font-weight: bold;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    @media #{$cr19-large-up} {
                      padding-#{$ldirection}: 0;
                    }
                  }
                  .ship_method_group,
                  .ship-method-group {
                    display: none !important;
                  }
                  .content {
                    padding: 0 16px;
                  }
                  #checkout_shipmethod {
                    .ship-method-group-label {
                      @include display-flex;
                      float: none;
                      padding-#{$rdirection}: 0;
                      padding-bottom: 7px;
                      @media #{$cr19-large-up} {
                        padding-bottom: 14px;
                      }
                      .ship-method-clickcollect {
                        margin-#{$ldirection}: 24px;
                        position: relative;
                      }
                      #form--checkout_shipmethod--field--SHIP_METHOD_GROUP--index--_label {
                        margin-#{$rdirection}: 18px !important;
                        @media #{$cr19-large-up} {
                          margin-#{$rdirection}: 0 !important;
                        }
                      }
                    }
                    .ship-method-home-group {
                      display: none !important;
                    }
                  }
                  .ship-methods {
                    font-family: $helvetica-regular-font-family;
                    font-size: 12px;
                    text-transform: uppercase;
                    &::before {
                      bottom: 0;
                      content: '';
                      display: block;
                      padding-#{$ldirection}: 20px;
                      position: absolute;
                      #{$rdirection}: 0;
                      top: 0;
                    }
                  }
                  .ship-method-home {
                    @include swap_direction(margin, 0 12px 0 0);
                  }
                }
                .delivery-options-title {
                  font-family: $helvetica-bold-font-family;
                  font-size: 22px;
                  font-weight: bold;
                  letter-spacing: 0.25px;
                  margin: 0 0 24px;
                  text-transform: uppercase;
                }
                .ship-method-home-group {
                  display: inline-grid;
                  margin: 24px 0 0;
                  padding: 0 17px;
                  width: 100%;
                  @media #{$cr19-large-up} {
                    padding: 0;
                  }
                  .shipmethod-home-delivery {
                    border: 1px solid $color-light-grey-2;
                    box-sizing: border-box;
                    margin: 0 0 16px;
                    position: relative;
                    &:last-child {
                      margin: 0;
                    }
                    label {
                      position: static;
                    }
                    .shipmethod-price {
                      #{$rdirection}: 17px;
                      position: absolute;
                    }
                    input[type='radio'] {
                      @include swap_direction(margin, 17px 11px 17px 18px);
                      float: $ldirection;
                      &:checked,
                      &:disabled + label {
                        pointer-events: none;
                      }
                      &:disabled + label {
                        opacity: 0.6;
                      }
                    }
                  }
                }
                .ship-method-group {
                  margin-top: 14px;
                  label {
                    font-size: 14px;
                    font-family: $helvetica-regular-font-family;
                    float: $ldirection;
                    letter-spacing: 0.25px;
                    line-height: 1.43;
                    padding: 17px 0;
                    position: relative;
                    width: 80%;
                    &.no-hide {
                      padding: 0 17px;
                      @media #{$cr19-large-up} {
                        padding: 0;
                      }
                    }
                  }
                }
                @media #{$cr19-large-up} {
                  .section-hasloyalty-promotions {
                    margin: 0 0 40px;
                  }
                }
                .click-and-collect {
                  .ship_group_0 {
                    display: none;
                  }
                  .sms-updates {
                    margin: 0;
                  }
                  .set-collection-point {
                    .checkout__panel-title {
                      display: none;
                    }
                    .form-item {
                      margin: 0;
                    }
                  }
                  .show-collection-point {
                    .checkout__panel-title {
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      font-weight: bold;
                      line-height: 0.875;
                      margin: 44px 0 5px;
                      width: 70%;
                    }
                    .form-item {
                      color: $color-light-grey-2;
                      font-family: $helvetica-regular-font-family;
                      font-weight: normal;
                      letter-spacing: 0.25px;
                      line-height: 1.4;
                      margin: 0;
                      @media #{$cr19-large-up} {
                        float: $ldirection;
                        width: 70%;
                      }
                    }
                  }
                  .local-collection-link {
                    color: $color-cl-light-black;
                    display: block;
                    font-size: 14px;
                    margin-top: 24px;
                    position: relative;
                    text-decoration: underline;
                    @media #{$cr19-large-up} {
                      float: $rdirection;
                      margin-top: 0;
                      top: -20px;
                    }
                  }
                }
                .local-collection-button {
                  float: $rdirection;
                  font-family: $helvetica-bold-font-family;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 700;
                  #{$ldirection}: 11px;
                  margin-top: 30px;
                  text-align: center;
                  width: 100%;
                  @media #{$cr19-large-up} {
                    #{$ldirection}: 0;
                    height: 48px;
                    margin-top: 54px;
                    width: 348px;
                  }
                }
              }
              .info-link-content {
                @include display-flex;
                margin: 20px 0 20px;
                padding: 0 17px;
                @media #{$cr19-large-up} {
                  margin-top: 28px;
                  margin-bottom: 35px;
                  padding: 0;
                }
              }
              .packstation_title {
                .info-link-message {
                  #{$ldirection}: 3px;
                  display: inline-block;
                  height: 20px;
                  margin: 0;
                  top: 4px;
                  width: 20px;
                  @media #{$cr19-large-up} {
                    top: 2px;
                  }
                }
              }
              .info-link-message {
                background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat
                  center;
                background-size: 100%;
                cursor: pointer;
                display: block;
                float: $ldirection;
                height: 20px;
                opacity: 0.9;
                position: relative;
                text-align: $ldirection;
                text-indent: -9999px;
                width: 20px;
              }
              .info-link {
                @include swap_direction(margin, 0 0 0 7px);
                bottom: 0;
                color: $cr19-text;
                font-size: 14px;
                line-height: 1.43;
                position: relative;
                text-decoration: underline;
              }
              #continue-btn {
                @include box-shadow(none);
                margin-top: 0;
                @media #{$cr19-large-up} {
                  margin-bottom: 50px !important;
                  margin-top: 66px;
                }
                .shipping-continue-btn {
                  @include box-shadow(0 -4px 8px $color-black-2opacity);
                  @include appearance(none);
                  z-index: 102;
                  position: fixed;
                  bottom: 0;
                  height: auto;
                  #{$ldirection}: 0;
                  #{$rdirection}: auto;
                  display: block;
                  width: 100%;
                  background: $color-white;
                  padding: 8px 9px;
                  @media #{$cr19-large-up} {
                    @include box-shadow(none);
                    position: static;
                    padding: 0;
                  }
                  .form-submit {
                    @include border-radius(0);
                    font-size: 17px;
                    font-style: normal;
                    font-weight: bold;
                    height: 48px;
                    line-height: 1.5;
                    margin: 0;
                    position: relative;
                    width: 100%;
                    @media #{$cr19-large-up} {
                      width: 348px;
                    }
                  }
                  .disable-btn {
                    pointer-events: none;
                    background-color: $color-gray-tier4;
                  }
                }
              }
              .select-address {
                .address-to-use {
                  padding: 0;
                  &.new-form-expand {
                    .ship_group_1 {
                      border-bottom: 0;
                    }
                  }
                }
                .ship_group_1 {
                  border-bottom: 1px solid $color-gray-tier2;
                  padding: 0 18px 15px;
                  @media #{$cr19-large-up} {
                    padding: 0 0 15px;
                    margin-bottom: 30px;
                  }
                  label {
                    text-transform: uppercase;
                    font-family: $helvetica-bold-font-family;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.1;
                    position: relative;
                    bottom: 4px;
                    #{$ldirection}: 8px;
                    letter-spacing: 0.1px;
                  }
                }
              }
              #shipping-panel,
              .field-container {
                .change-address,
                #shipping-panel-edit,
                .change_link {
                  background-image: url('/media/images/checkout/edit_icon.png');
                  background-size: contain;
                  background-repeat: no-repeat;
                  bottom: 25px;
                  color: $color-cl-light-black;
                  float: $rdirection;
                  height: 24px;
                  position: relative;
                  text-indent: -9999px;
                  width: 24px;
                  z-index: 1;
                  @media #{$cr19-large-up} {
                    margin-#{$rdirection}: 16px;
                  }
                  &.cancel_link {
                    background: none;
                    font-size: 14px;
                    font-family: $helvetica-bold-font-family;
                    text-decoration: underline;
                    float: $rdirection;
                    position: relative;
                    #{$rdirection}: 20px;
                    text-indent: 0;
                    bottom: 25px;
                    width: auto;
                    @media #{$cr19-large-up} {
                      margin-#{$rdirection}: 16px;
                    }
                  }
                }
                input[type='radio'] {
                  @include appearance(none);
                  @include border-radius(50%);
                  @include swap_direction(margin, 10px 10px 0 0);
                  width: 20px;
                  height: 20px;
                  border: 1px solid;
                  position: relative;
                  &:checked {
                    &::before {
                      @include border-radius(50%);
                      content: '';
                      display: block;
                      width: 14px;
                      height: 14px;
                      margin: 0 auto;
                      background: $color-black;
                      position: absolute;
                      top: 2px;
                      #{$ldirection}: 2px;
                      @media #{$cr19-large-up} {
                        #{$rdirection}: 5px;
                        bottom: 1px;
                      }
                    }
                  }
                }
              }
              .field-container {
                .select-menu {
                  border-top: 1px solid $color-gray-tier2;
                  margin-top: 16px;
                  margin-bottom: 6px;
                  .full_divider {
                    padding: 0 17px;
                    margin-top: 6px;
                    border-bottom: 1px solid $color-gray-tier2;
                    @media #{$cr19-large-up} {
                      padding: 0;
                    }
                    &.delivery-address-container {
                      margin-top: 0;
                      input[type='radio'] {
                        float: $ldirection;
                        & + label {
                          margin-bottom: 22px;
                        }
                      }
                      .change_link,
                      .delivery_address {
                        top: 10px;
                      }
                    }
                    .edit-address-container {
                      padding: 0;
                      .edit-shipping-address {
                        .address.fs {
                          margin-top: 0;
                          .delivery_instructions {
                            label {
                              padding: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                  .delivery_address {
                    #{$ldirection}: 12px;
                    margin-#{$ldirection}: 29px;
                    bottom: 22px;
                    color: $cr19-text;
                    font-family: $helvetica-regular-font-family;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    line-height: 1.4;
                    position: relative;
                    width: 50%;
                    &.nonreg_user_address {
                      .co_default {
                        display: none;
                      }
                    }
                    a[href^='tel'] {
                      color: inherit;
                      font-family: inherit;
                      font-size: inherit;
                      font-weight: inherit;
                    }
                  }
                  .co_default {
                    font-family: $helvetica-bold-font-family;
                    font-weight: 700;
                    line-height: 1.43;
                  }
                }
              }
            }
          }
        }
      }
    }
    .checkout {
      &__content {
        .with-field-lookup {
          .find-town {
            .valid_marker {
              top: auto;
              #{$rdirection}: 15px !important;
              @media #{$cr19-large-up} {
                #{$rdirection}: 25px !important;
              }
            }
            .invalid_marker {
              top: auto;
              #{$rdirection}: 20px;
              @media #{$cr19-large-up} {
                #{$rdirection}: 35px;
              }
            }
          }
        }
        span.error {
          color: $color-red;
        }
      }
    }
    .shipping-panel,
    .review-panel,
    .guarantee-panel,
    .sign-in-panel {
      visibility: hidden;
    }
  }
  .opc__shipping {
    &.enhanced-delivery-page,
    .enhanced-delivery-page {
      #main.single {
        .responsive-container {
          .pg_wrapper {
            .column {
              &.right {
                .panel {
                  &.shipping-address-panel,
                  &.billing-address-panel,
                  &.shipmethod-panel {
                    display: none !important;
                  }
                }
                .continue-payment-button {
                  display: none;
                }
              }
            }
          }
        }
      }
      .shipping-panel,
      .review-panel,
      .guarantee-panel,
      .sign-in-panel {
        visibility: visible;
      }
    }
  }
  .opc__review {
    &.enhanced-delivery-page,
    .enhanced-delivery-page {
      #main.single {
        .responsive-container {
          .pg_wrapper {
            .column {
              &.left {
                .panel {
                  &.guarantee-panel,
                  &.sign-in-panel,
                  &.shipping-panel {
                    display: none;
                  }
                }
              }
              &.checkout__sidebar {
                .continue-checkout-bottom {
                  display: none;
                }
              }
            }
          }
        }
      }
      .shipping-panel,
      .review-panel,
      .guarantee-panel,
      .sign-in-panel {
        visibility: visible;
      }
    }
  }
  .show {
    display: block !important;
  }
}

.opc__signin {
  &:not(.enhanced-delivery-page) {
    #main {
      .checkout {
        &__content {
          .guarantee-panel__title {
            font-size: 34px;
            padding: 0.3em;
          }
        }
        &__sidebar {
          .continue-buttons,
          .sticky-back-to-top,
          .shipping-address-panel,
          .billing-address-panel,
          .shipmethod-panel {
            display: none;
          }
        }
      }
    }
  }
}
