html.no-js .nojs_hidden,
html.js .js_hidden {
  display: none;
}
/* overrides */
.pc_spp_view {
  .page-spp {
    .breadcrumbs {
      margin: 0 0 30px 0;
      padding: 15px 0 0 0;
      li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 1px;
        padding: 0;
        color: black;
        padding: 0 0 0 5px;
      }
    }
  }
  .module-spp-detail {
    .page-spp {
      .container {
        .col2 {
          color: #000000;
          float: #{$ldirection};
          width: 56%;
          font-size: 15px;
          font-family: $helvetica-light-font-family;
          h1 {
            font-size: 36px;
            line-height: 1;
            margin: 0 0 15px;
            letter-spacing: -0.3mm;
          }
          .abstract {
            margin: 10px 0;
          }
        }
        .col1 {
          float: #{$ldirection};
          margin: 0 2% 0 0;
          width: 42%;
          position: relative;
          img.full {
            width: auto;
          }
          .badge {
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            text-align: center;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            color: #fff;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $helvetica-light-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }
  }
}

#shipping {
  #checkout_shipmethod {
    .ship-method-group-label {
      margin: 0 0 10px 0;
      input[type='radio'] {
        float: none;
        vertical-align: middle;
        margin: 0 10px 0 10px;
        &:first-child {
          margin: 0 10px 0 0px;
        }
      }
    }
  }
}

#viewcart {
  #works-well-with-wrapper {
    table {
      height: 170px;
      position: relative;
      .add {
        position: absolute;
        bottom: 0px;
        width: 120px;
      }
    }
  }
}

.what-people-are-saying-module-v2 {
  .smoosh-wrapper {
    height: 121px;
    width: 118px;
  }
}

// GWP/MYOG styles start here
//colors
$color_star_dust_approx: #9e9ea0;

.wp-sample-page-pc {
  .wp_messages {
    font-size: 16px;
    text-align: center;
    padding: 2% 10%;
  }
  .no-offer-msg {
    margin-top: 6%;
  }
  .product {
    padding: 0;
    float: left;
  }
  .column-1 {
    width: 99% !important;
    padding-left: 36%;
    .product-img img {
      width: auto !important;
    }
  }
  .column-2 {
    width: 48% !important;
    padding-left: 10%;
    .product-img img {
      width: auto !important;
    }
  }
  .column-3 {
    width: 30% !important;
    padding-left: 6%;
    .product-img img {
      width: 290px;
      height: 315px;
    }
  }
  .cat_title {
    font-size: 1.7em;
    h3 {
      margin-bottom: 3px;
    }
  }
  .wp-label-wrap {
    padding-top: 3%;
  }
  .sample-controls {
    margin: 20px 0;
  }
  input[type='radio'] {
    display: inline !important;
  }
  input[type='checkbox'] {
    display: inline !important;
  }
  .myog-btn-holder {
    float: right;
    padding-top: 10%;
  }
  .wp-footer-top {
    margin: 1% 0 3% 0;
    text-align: left;
  }
  .wp-footer-bottom {
    margin-bottom: 5%;
    text-align: left;
  }
  .checkbox-disable {
    color: $color_star_dust_approx;
    cursor: default;
  }
  .button-disable {
    background-color: $color_celeste_approx;
    cursor: default;
    &:hover {
      background-color: $color_celeste_approx;
      cursor: default;
    }
  }
  .clear-both {
    clear: both;
  }
  .float-none {
    float: none;
  }
  #content img {
    width: 100%;
    padding-bottom: 2%;
  }
}

#viewcart {
  .wp_message {
    font-size: 16px;
  }
  .wp-name {
    font-size: 10px;
  }
  .wp-desc {
    margin-bottom: 1%;
    color: #231f20;
    font-weight: bold;
  }
  .offer-txt {
    color: #231f20;
  }
  .loading-img {
    position: absolute;
    padding: 0 0 0 1%;
  }
}

#review {
  .wp_message {
    font-size: 16px;
  }
  .wp-name {
    font-size: 10px;
  }
  .wp-desc {
    margin-bottom: 1%;
    color: #231f20;
    font-weight: bold;
  }
  .offer-txt {
    color: #231f20;
  }
  .myog-remove {
    margin-top: 5%;
  }
  .loading-img {
    position: absolute;
    padding: 0 0 0 1%;
  }
}
// GWP/MYOG styles ends here

#main.single {
  .responsive-container {
    .column.right #offer-code-panel {
      .offer-code__submit {
        text-align: left !important;
      }
    }
  }
  .product {
    &.shaded {
      .color {
        margin-top: 5px;
        .shade {
          position: relative;
          left: 40%;
          white-space: nowrap;
        }
      }
      .cart-item__remove-form {
        position: relative;
        margin-top: 5px;
      }
    }
  }
}

.registration_content {
  .first-column-wrap {
    fieldset.fs {
      .form_element {
        input.error {
          border: 1px solid $color-notice-pink !important;
        }
      }
    }
  }
}

#address_form_container {
  fieldset.fs {
    div.radio {
      span {
        display: inline;
      }
    }
  }
}

#viewcart-shopping-bag-panel {
  .product__desc-info {
    .color {
      display: none;
    }
  }
}

.spc_enabled {
  #main {
    .clinical-reality {
      .product-image {
        .product-add-to-bag {
          display: none;
        }
        &:hover {
          .product-add-to-bag {
            display: block;
          }
        }
      }
    }
  }
}

.viewcart-paypal-button {
  .paypal-button {
    float: $ldirection;
    margin-left: 35px;
  }
  .terms-conditions {
    float: $ldirection;
    text-align: $ldirection;
    input {
      float: $ldirection;
      margin-#{$ldirection}: 170px;
    }
    &-txt {
      width: 40%;
      margin-top: 6px;
      margin-#{$ldirection}: 185px;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .spc_enabled {
    #main {
      &.single {
        .checkout {
          .column {
            &.left {
              margin: 0;
              float: left;
              width: 65%;
              padding-right: 2em;
            }
          }
        }
      }
    }
  }
}

.site-phone-number-v1 {
  height: 72px;
  display: none;
  @media all and (min-width: $medium-max) {
    display: table;
  }
  .phone {
    height: 100%;
    top: 50%;
    display: table-cell;
    vertical-align: middle;
    width: auto;
    background: 8px 26px url('/media/images/icons/call-volume.svg') no-repeat;
    background-size: 20px 20px;
    a {
      color: $color-cl-green-2;
      font-weight: bold;
      padding: 0 0 0 30px;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.popup-offer-v1 {
  .outer-wrap {
    background: none repeat scroll 0 0 $color-white;
    border: 2px solid $color-black;
    height: 353px;
    left: 37.3%;
    overflow: hidden;
    padding: 20px 20px 15px;
    position: fixed;
    top: 20%;
    width: 466px;
    z-index: 10000;
  }
  .popup-offer-close {
    background: transparent url(/sites/clinique/themes/cl_base/img/icon-close.png) no-repeat scroll
      0 0/15px 15px;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 5px;
    text-align: left;
    top: 5px;
    width: 15px;
  }
}

.random-gift-picker-formatter {
  .pop-up-box {
    @include breakpoint($medium-max) {
      max-height: 684px;
    }
    @include breakpoint($xlarge-max) {
      max-height: 684px;
    }
    &--game-error,
    &--game-unavailable {
      .pop-up-box-inner {
        max-width: 90%;
        border: 6px solid $color-spin-pink;
        outline: 2px solid $color-spin-pink;
        outline-offset: -11px;
        font-size: 28px;
      }
    }
  }
}
/* CL Randomizer Game */
section.clinique-spinner section#game-win-box {
  .win-box-btns {
    .right-btn-box,
    .single-btn-box {
      .button {
        @include breakpoint($small-max) {
          @include swap_direction(padding, 5px 10px);
        }
      }
    }
  }
}

@include breakpoint($small-max) {
  section {
    &.clinique-spinner section#game-win-box {
      .win-image,
      .win-image--mobile {
        height: 200px;
      }
    }
  }
}

section.clinique-spinner {
  .game-spinner {
    @include breakpoint($medium-max) {
      @include swap_direction(margin, 0 7px);
    }
    @include breakpoint($small-max) {
      @include swap_direction(margin, 0 3px);
    }
  }
}

.checkout {
  &__sidebar {
    a {
      &.disabled {
        background: $color-grey;
      }
    }
  }
}

.pc_email_promo_container {
  .field_label {
    font-family: $helvetica-regular-font-family;
  }
}

.cs-page-section-formatter-v1 {
  ul {
    font-family: $base-font-family;
    font-size: 22px;
  }
}
