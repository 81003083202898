@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        #signin &,
        #gnav_signin,
        #billing & {
          width: 33%;
        }
        #password_reset & {
          top: -12%;
          width: auto;
        }
      }
    }
  }
  #password_reset {
    .password-field {
      &__rules {
        @media #{$medium-up} {
          column-count: 2;
        }
      }
    }
  }
  #confirm {
    .password-field {
      &__info {
        #{$ldirection}: 255px;
        bottom: -20px;
        &::before {
          @media #{$medium-up} {
            content: '';
            position: absolute;
            top: 37%;
            #{$ldirection}: -7%;
            border-width: 5px;
            border-style: solid;
            border-color: transparent $color-btn-grey transparent transparent;
            transform: rotate(-2deg);
            -ms-transform: rotate(-2deg);
            -webkit-transform: rotate(-2deg);
            -o-transform: rotate(-2deg);
          }
        }
      }
    }
  }
  .loyalty_popover {
    .password-field {
      &__info {
        &::before {
          @media #{$medium-up} {
            top: 100%;
            right: 55%;
            transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            -o-transform: rotate(270deg);
          }
        }
      }
    }
  }
  #signin {
    #gnav_signin {
      .password-field {
        &__info {
          width: 28%;
          &::before {
            @media #{$medium-up} {
              top: 100%;
              right: 63%;
              transform: rotate(2deg);
              -ms-transform: rotate(2deg);
              -webkit-transform: rotate(2deg);
              -o-transform: rotate(2deg);
            }
          }
        }
      }
    }
  }
  #gnav_signin {
    .password-field {
      &__info {
        width: 28%;
        &::before {
          @media #{$medium-up} {
            top: -12%;
            right: 63%;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -o-transform: rotate(90deg);
          }
        }
      }
    }
  }
  .profile-info__change_pwd_link {
    margin-top: 30px;
  }
  #registration {
    .profile-password-update {
      .form-submit {
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        display: flex;
        color: $color-black;
      }
    }
  }
}
