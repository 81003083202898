#viewcart #main.single .responsive-container .panel .product {
  &.shopping-bag-description {
    margin: 0;
    position: static;
  }
}

.spc_enabled {
  &.enhanced-delivery-page:not([class*='enhanced_signin_page']),
  .enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column {
            &.right {
              .address-title {
                font-weight: normal;
              }
              .checkout__sidebar-items {
                @include flex-direction(column);
                @include order(1);
                display: none;
                &.checkout-loyalty-offers,
                &.active {
                  display: flex;
                }
                @media #{$cr19-large-up} {
                  @include display-flex;
                }
              }
              .expand-toggle {
                margin: 0;
                position: relative;
                background: none;
                width: 24px;
                height: 24px;
                top: -2px;
                &::before,
                &::after {
                  @include transform(translate(-50%, -50%));
                  content: '';
                  background: $color-black;
                  display: block;
                  height: 2px;
                  width: 16px;
                  position: absolute;
                  #{$ldirection}: 50%;
                  top: 50%;
                }
                &::after {
                  width: 2px;
                  height: 16px;
                }
              }
              .checkout-btns {
                display: none;
              }
              .continue-buttons {
                display: none;
                @media #{$cr19-large-up} {
                  padding: 24px 16px 25px;
                  border-bottom: 1px solid $cr19-border-light;
                  .btn {
                    font-size: 17px;
                    font-weight: 700;
                    height: 48px;
                    line-height: 1.4;
                    padding: 12px !important;
                    width: 100%;
                  }
                  .disable-btn {
                    pointer-events: none;
                    background-color: $color-gray-tier4;
                  }
                }
              }
              .panel {
                @include order(1);
                border-bottom: 1px solid $cr19-border-light;
                @media #{$cr19-large-up} {
                  background: $color-white;
                  border-bottom: 0;
                  border-top: 1px solid $cr19-border-light;
                }
                header,
                .checkout-header {
                  border: 0;
                }
                #delivery-address-h {
                  display: none;
                }
                .checkout__panel-title {
                  color: $cr19-text;
                  font-family: $helvetica-bold-font-family;
                  font-size: 15px !important;
                  font-weight: 700;
                  letter-spacing: 0.25px;
                  line-height: 1.75;
                  padding: 5px 0;
                  text-transform: uppercase;
                  width: 100%;
                  @media #{$cr19-large-up} {
                    line-height: 1.35;
                    margin: 0;
                    padding: 14px 0 12px;
                  }
                }
                .active {
                  .expand-toggle {
                    &:after {
                      visibility: hidden;
                    }
                  }
                }
                &#order-summary-panel {
                  border-top: 1px solid $color-grey;
                  display: block;
                  margin-top: 0;
                  padding: 5px 17px 3px;
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 14px 0 13px);
                    border-top: 0;
                    margin: 0;
                  }
                  .checkout__panel-title {
                    padding: 0;
                    line-height: get-line-height(15, 26);
                    @media #{$cr19-large-up} {
                      padding: 14px 0 12px;
                    }
                  }
                  .active {
                    .order-summary-panel__title {
                      .expand-toggle {
                        &::after {
                          visibility: hidden;
                        }
                      }
                    }
                  }
                  .order-summary__content {
                    font-size: 14px;
                    padding-top: 8px;
                    text-transform: capitalize;
                    @media #{$cr19-large-up} {
                      padding: 0;
                    }
                    .ship-label-sec {
                      border-bottom: 1px solid $color-gray-tier2;
                      padding-bottom: 16px;
                      margin-bottom: 20px;
                      @media #{$cr19-large-up} {
                        padding-bottom: 14px;
                        margin-bottom: 18px;
                      }
                      .shipping-label-data {
                        display: block;
                        background-color: $color-bg-light-grey;
                        padding: 14px;
                        font-size: 12px;
                        border: 1px solid $cr19-text-grey;
                        color: $cr19-text-grey;
                        line-height: 1.4;
                        letter-spacing: 0.25px;
                        .shipping-option {
                          display: none;
                        }
                      }
                    }
                    .label {
                      background: $color-white;
                      color: $cr19-text-grey;
                      font-weight: normal;
                      font-family: $helvetica-regular-font-family;
                      line-height: 1.45;
                      margin: 0 0 8px;
                      padding: 0;
                      @media #{$cr19-large-up} {
                        margin-bottom: 10px;
                      }
                      &.tax {
                        margin-bottom: 13px;
                        font-size: 12px;
                        line-height: 1.4;
                        letter-spacing: 0.25px;
                        color: $cr19-text-grey;
                      }
                      &.total {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 15px;
                        color: $cr19-text;
                        font-family: $helvetica-bold-font-family;
                      }
                    }
                    .value {
                      background: $color-white;
                      color: $cr19-text;
                      font-family: $helvetica-bold-font-family;
                      font-weight: bold;
                      height: auto;
                      line-height: 1.15;
                      margin: 0;
                      padding-#{$rdirection}: 1px;
                      &.tax {
                        display: none;
                      }
                    }
                    .discount-save {
                      clear: both;
                      margin: 10px 0;
                    }
                    .order-summary {
                      &__total {
                        &-label,
                        &-value {
                          line-height: 1.33;
                          margin-bottom: 10px;
                          padding: 0;
                        }
                      }
                    }
                  }
                }
                &#offer-code-panel {
                  @include order(1);
                  padding: 0 17px;
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 14px 0 13px);
                  }
                  .offer-code-panel__title {
                    padding: 10px 0;
                    @media #{$cr19-large-up} {
                      padding: 14px 0 12px;
                    }
                  }
                  .content {
                    padding: 7px 0 15px;
                    @media #{$cr19-large-up} {
                      padding: 0 0 15px;
                    }
                    .offer-code-error {
                      color: $color-monza-light;
                      font-size: 14px;
                      letter-spacing: 0.25px;
                      line-height: 1.45;
                      margin-bottom: 13px;
                      & + .offer-code-error,
                      .single-message {
                        display: none;
                      }
                      #invalid_offer_code {
                        display: block;
                      }
                      #offer_criteria_not_met_feb_gwp {
                        display: none;
                      }
                    }
                    p {
                      display: none;
                      &.note {
                        color: $color-cl-green-4;
                        display: block;
                        font-size: 14px;
                        letter-spacing: 0.25px;
                        line-height: 1.45;
                      }
                    }
                    #offer_code {
                      position: relative;
                      .offer-code__input {
                        @include appearance(none);
                        @include swap-direction(margin, 0 125px 0 0);
                        float: none;
                        height: 56px;
                        padding-top: 0;
                        position: relative;
                        width: auto;
                        .valid_marker,
                        .invalid_marker {
                          #{$rdirection}: 15px;
                          bottom: 12px;
                          height: 30px;
                          margin-bottom: 5px;
                          text-indent: -9999px;
                          width: 35px;
                          @media #{$cr19-large-up} {
                            #{$rdirection}: 25px;
                          }
                        }
                        .valid_marker {
                          background: url('/media/images/checkout/tick-icon_01.png') no-repeat 12px
                            7px;
                          background-size: 70%;
                        }
                        .invalid_marker {
                          #{$rdirection}: 20px;
                          background: url('/media/images/checkout/invalid-marker.png') no-repeat 4px
                            3px;
                          background-size: 100%;
                          top: auto;
                        }
                        .form--offer_code--field--OFFER_CODE {
                          background-image: linear-gradient(transparent, transparent);
                          border-radius: 0;
                          color: $color-gray-dusty;
                          width: 100%;
                          height: 56px;
                          @media #{$cr19-large-up} {
                            font-size: 16px;
                            line-height: 1.5;
                            letter-spacing: 1px;
                            color: $color-gray-dusty;
                          }
                          &:focus + label {
                            &::before {
                              @include transform(
                                translateY(-26px) translateY(-0.5px) scale(0.8, 0.8)
                              );
                              color: $color-cl-light-black;
                            }
                          }
                        }
                        label {
                          line-height: normal;
                          margin-top: -37px;
                          font-size: 16px;
                          cursor: text;
                          font-family: $helvetica-regular-font-family;
                          &::before {
                            color: $color-light-grey-2;
                            font-size: 12px;
                            line-height: 15px;
                          }
                        }
                      }
                      .offer-code__submit {
                        @include appearance(none);
                        position: absolute;
                        top: 0;
                        width: auto;
                        #{$rdirection}: 0;
                        .form-submit {
                          &.btn {
                            border-radius: 0;
                            width: 113px;
                            height: 56px;
                            background: $color-black;
                            border: 0;
                            color: $color-white;
                            margin-top: 0;
                            font-size: 15px;
                            font-weight: 700;
                            font-family: $helvetica-bold-font-family;
                          }
                        }
                      }
                      .offer-code__see-link {
                        text-align: center;
                        padding: 28px 0 11px;
                        float: none;
                        .continue-shopping {
                          border: 0;
                          text-decoration: underline;
                          color: $color-black;
                          font-size: 14px;
                          float: none;
                          font-family: $helvetica-regular-font-family;
                          line-height: 1;
                          padding: 0;
                        }
                      }
                    }
                  }
                }
                &.shipping-address-panel {
                  @include order(1);
                  padding: 5px 17px;
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 14px 0 13px);
                  }
                  #corporate-invoice-display {
                    display: none;
                    height: 0;
                  }
                  .panel-group {
                    clear: none;
                  }
                  .checkout-header {
                    border: 0;
                    .checkout__subtitle {
                      line-height: 1.8;
                      font-size: 15px;
                      letter-spacing: 0.25px;
                      text-transform: uppercase;
                      color: $cr19-text;
                      padding: 5px 0;
                      @media #{$cr19-large-up} {
                        font-weight: 700;
                        line-height: 1.4;
                        margin-top: 0;
                        padding: 14px 0 12px;
                      }
                    }
                  }
                  #shipping-panel-edit {
                    background: url('/media/images/checkout/edit_icon.png') no-repeat;
                    background-size: 18px;
                    border: 0;
                    float: $rdirection;
                    height: 18px;
                    position: relative;
                    #{$rdirection}: 3px;
                    text-indent: -9999px;
                    top: 0;
                    width: 18px;
                  }
                  .shipping-address-display {
                    padding: 0;
                    p {
                      line-height: 1.45;
                      letter-spacing: 0.25px;
                      @media #{$cr19-large-up} {
                        margin: 0;
                      }
                      a[href^='tel'] {
                        color: inherit;
                      }
                    }
                  }
                }
                &.billing-address-panel {
                  @include order(1);
                  padding: 5px 17px;
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 14px 0 13px);
                  }
                  .checkout__subtitle {
                    margin-top: 1px;
                    font-size: 15px;
                    line-height: 1.6;
                    letter-spacing: 0.25px;
                    text-transform: uppercase;
                    color: $cr19-text;
                    padding: 5px 0;
                    @media #{$cr19-large-up} {
                      margin-top: 0;
                      font-weight: 700;
                      padding: 14px 0 12px;
                    }
                  }
                  .change-address {
                    background: url('/media/images/checkout/edit_icon.png') no-repeat;
                    background-size: 18px;
                    height: 18px;
                    position: relative;
                    width: 18px;
                    #{$rdirection}: 5px;
                    top: 10px;
                    float: $rdirection;
                    text-indent: -9999px;
                    @media #{$cr19-large-up} {
                      top: 0;
                    }
                  }
                  .select-address {
                    .same-as-shipping {
                      display: none;
                    }
                    .payment-address {
                      font-size: 14px;
                      line-height: 1.45;
                      letter-spacing: 0.25px;
                      color: $cr19-text;
                      padding-top: 10px;
                      @media #{$cr19-large-up} {
                        padding-top: 0;
                      }
                      a[href^='tel'] {
                        color: inherit;
                      }
                    }
                  }
                }
                &#shippingmethod-panel {
                  @include order(1);
                  @include swap_direction(padding, 5px 15px 5px 17px);
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 14px 0 13px);
                  }
                  .checkout__subtitle {
                    padding: 5px 0;
                    @media #{$cr19-large-up} {
                      padding: 14px 0;
                    }
                  }
                  .content {
                    padding: 0;
                  }
                  .edit-shipmethod {
                    font-size: 14px;
                    margin: 0 0 18px;
                    text-transform: uppercase;
                    .edit-shipmethod-link {
                      background: url('/media/images/checkout/edit_icon.png') no-repeat;
                      background-size: 18px;
                      float: $rdirection;
                      height: 20px;
                      line-height: 1.2;
                      position: absolute;
                      text-indent: -9999px;
                      width: 20px;
                      #{$rdirection}: 16px;
                    }
                  }
                  #checkout_shipmethod {
                    .ship-method-group {
                      margin: 8px 0 5px;
                      .form-item {
                        margin: 0;
                        label {
                          &.no-hide {
                            display: block !important;
                            font-size: 14px;
                            line-height: 1.3;
                            letter-spacing: 0.25px;
                            margin-bottom: 8px;
                            text-transform: uppercase;
                            color: $color-cl-light-black;
                            @media #{$cr19-large-up} {
                              margin-bottom: 15px;
                            }
                          }
                        }
                      }
                      .content {
                        padding-bottom: 5px;
                        font-family: $helvetica-regular-font-family;
                      }
                      &.ship-method-collection-group {
                        display: none;
                      }
                    }
                  }
                }
                &.shipmethod-panel,
                &.shipping-address-panel,
                &.billing-address-panel {
                  display: none;
                  .checkout__subtitle {
                    cursor: pointer;
                    margin-top: 1px;
                    font-size: 15px !important;
                    font-family: $helvetica-bold-font-family;
                    line-height: 1.75;
                    letter-spacing: 0.25px;
                    text-transform: uppercase;
                    color: $cr19-text;
                    font-weight: 700;
                    position: relative;
                    @media #{$cr19-large-up} {
                      margin-top: 0;
                      line-height: 1.4;
                    }
                    &:before,
                    &:after {
                      @include translate(-50%, -50%);
                      content: '';
                      background: $color-black;
                      display: block;
                      height: 2px;
                      width: 16px;
                      float: $rdirection;
                      position: absolute;
                      #{$rdirection}: -4px;
                      top: 18px;
                      @media #{$cr19-large-up} {
                        top: 23px;
                      }
                    }
                    &:after {
                      width: 2px;
                      height: 16px;
                      #{$rdirection}: 10px;
                    }
                    &.active {
                      &:after {
                        visibility: hidden;
                      }
                    }
                  }
                  .active {
                    .checkout__subtitle {
                      &:after {
                        visibility: hidden;
                      }
                    }
                  }
                  .address-content {
                    margin-bottom: 16px;
                    padding: 0 !important;
                  }
                  .change-address {
                    position: relative;
                    #{$rdirection}: 3px;
                  }
                }
                &.shopping-bag-panel {
                  @include order(1);
                  padding: 10px 17px;
                  border-bottom: 0;
                  display: block;
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 14px 0 13px);
                  }
                  .shopping-bag-panel__title {
                    font-size: 15px !important;
                    font-family: $helvetica-bold-font-family;
                    line-height: 1.8;
                    letter-spacing: 0.25px;
                    padding: 0;
                    position: relative;
                    text-transform: uppercase;
                    color: $cr19-text;
                    margin: 0;
                    font-weight: 700;
                    @media #{$cr19-large-up} {
                      line-height: 1.4;
                      padding: 14px 0 12px;
                    }
                    span,
                    br {
                      display: none;
                    }
                    .item-count__number {
                      display: inline-block;
                    }
                    .edit-cart {
                      float: $rdirection;
                      background: url('/media/images/checkout/edit_icon.png') no-repeat;
                      background-size: 18px;
                      height: 18px;
                      position: absolute;
                      width: 18px;
                      #{$rdirection}: 3px;
                      top: 3px;
                      text-indent: -9999px;
                      @media #{$cr19-large-up} {
                        top: 12px;
                      }
                    }
                  }
                  .item-count {
                    display: none;
                  }
                }
                &#viewcart-shopping-bag-panel {
                  @include order(1);
                  border-top: 0;
                  display: block;
                  margin-top: 0;
                  #gift-options-display {
                    margin-bottom: 0;
                    padding: 0 21px 10px 13px;
                    width: 100%;
                    .gift-wrap-left {
                      float: $ldirection;
                      padding-#{$rdirection}: 7px;
                      width: 100px;
                    }
                    .giftwrap-right {
                      float: $rdirection;
                      margin-bottom: 0;
                      width: calc(100% - 107px);
                      .gift-options-header {
                        font-family: $helvetica-bold-font-family;
                        font-size: 12px;
                        font-weight: bold;
                        margin-bottom: 5px;
                        text-transform: uppercase;
                      }
                      .checkout__subtitle {
                        color: $cr19-text-grey;
                        font-family: $helvetica-bold-font-family;
                        font-size: 12px !important;
                        font-style: normal;
                        font-weight: normal;
                        letter-spacing: 0.25px;
                        line-height: 1.35;
                        margin-top: 0;
                        padding: 0;
                        text-transform: capitalize;
                        width: 100%;
                        @media #{$cr19-large-up} {
                          margin-bottom: 5px;
                        }
                      }
                      p {
                        color: $cr19-text-grey;
                        font-size: 12px;
                        line-height: 1.35;
                        padding: 0;
                      }
                    }
                  }
                  .panel-group {
                    #shipping-address-display,
                    #corporate-invoice-display {
                      display: none;
                    }
                    #gift-options-display {
                      @include display-flex;
                      width: 100%;
                      #gift-message-h {
                        display: none;
                      }
                      img {
                        margin-#{$rdirection}: 8px;
                        height: 73.08px;
                        width: 100px;
                      }
                      #gift-options-h {
                        p {
                          font-size: 12px;
                          &:nth-child(1) {
                            display: none;
                          }
                        }
                        h3 {
                          font-size: 12px;
                          font-weight: 700;
                          text-transform: uppercase;
                        }
                      }
                    }
                  }
                  .viewcart-panel__content {
                    @include swap_direction(padding, 0 14px 10px 0);
                    @media #{$cr19-large-up} {
                      padding-#{$ldirection}: 13px;
                      padding-#{$rdirection}: 21px;
                    }
                    .cart-item {
                      border: 0;
                      padding-top: 6px;
                      @media #{$cr19-large-up} {
                        &:first-child {
                          padding-top: 0;
                        }
                        margin: 0;
                      }
                      &__thumb {
                        padding-#{$rdirection}: 7px;
                        width: 107px;
                      }
                      &__remove-form {
                        display: none;
                      }
                      &__desc {
                        word-break: break-all;
                        padding: 0;
                      }
                      &__product-name {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 1.35;
                        letter-spacing: 0.25px;
                        text-transform: uppercase;
                        color: $cr19-text;
                        font-family: $helvetica-bold-font-family;
                        padding: 0;
                        width: 100%;
                        @media #{$cr19-large-up} {
                          margin-bottom: 5px;
                        }
                      }
                      &__size {
                        color: $cr19-text-grey;
                        font-family: $helvetica-regular-font-family;
                        font-size: 12px;
                        line-height: 1.1;
                      }
                      &__product-subname,
                      &__add-to-favorites {
                        display: none;
                      }
                      &__price {
                        display: none;
                        &.mobile_visibility {
                          display: block;
                          float: $ldirection;
                          padding: 16px 0 0;
                          margin-top: 0;
                          clear: none;
                          width: auto;
                          @media #{$cr19-large-up} {
                            font-size: 14px;
                            line-height: 1.2;
                            color: $cr19-text-grey;
                          }
                          .price-label {
                            display: none;
                          }
                        }
                      }
                      &__unit-price {
                        font-size: 14px;
                        line-height: normal;
                        margin-#{$ldirection}: 12px;
                        color: $cr19-text;
                        width: auto;
                        float: $rdirection;
                      }
                      &__disc-unit-price,
                      &__discount-percentage {
                        color: $cr19-text-grey;
                        float: none;
                      }
                      &__discount-percentage {
                        padding-#{$ldirection}: 5px;
                      }
                      .unit-discount {
                        float: $ldirection;
                        width: 48%;
                        @media #{$cr19-large-up} {
                          width: 52%;
                        }
                      }
                      &__qty {
                        width: 64%;
                        float: $ldirection !important;
                        font-size: 12px;
                        line-height: 1.1;
                        color: $cr19-text-grey;
                        margin-top: 5px;
                        padding: 0;
                        top: 14px;
                        .sample_item_qty {
                          display: none;
                        }
                      }
                      &__total {
                        display: block;
                        position: relative;
                        font-weight: bold;
                        letter-spacing: 0.25px;
                        line-height: 1.35;
                        font-family: $helvetica-bold-font-family;
                        padding-top: 16px;
                        text-align: $rdirection;
                        width: 38%;
                        @media #{$cr19-large-up} {
                          margin: 0;
                          width: 35%;
                        }
                      }
                      .responsive-shade-shim,
                      .remove_link {
                        display: none;
                      }
                      .shopping-bag-description {
                        float: $ldirection;
                        width: calc(100% - 107px);
                        .desc {
                          width: 90%;
                          .remove-sample {
                            width: 100%;
                          }
                        }
                        .cart-item__price {
                          float: $ldirection;
                          width: 62%;
                          @media #{$cr19-large-up} {
                            width: 65%;
                          }
                        }
                      }
                    }
                  }
                }
                &#links-panel {
                  border-top: 1px solid $cr19-border-light;
                  display: block;
                  margin-top: 14px;
                  padding: 5px 17px 3px;
                  @media #{$cr19-large-up} {
                    text-align: center;
                    background: $cr19-bg-light;
                    margin-top: 0;
                    padding-top: 22px;
                  }
                  header {
                    border: 0;
                    @media #{$cr19-large-up} {
                      height: 20px;
                      pointer-events: none;
                    }
                  }
                  .links-panel__title {
                    color: $cr19-text;
                    font-family: $helvetica-bold-font-family;
                    font-weight: bold;
                    font-size: 15px !important;
                    line-height: get-line-height(15, 26);
                    letter-spacing: 0.25px;
                    padding: 0;
                    text-transform: uppercase;
                    @media #{$cr19-large-up} {
                      line-height: 1.4;
                    }
                    .expand-toggle {
                      #{$rdirection}: 0;
                      @media #{$cr19-large-up} {
                        display: none;
                      }
                    }
                  }
                  .active {
                    .links-panel__title {
                      .expand-toggle {
                        &:after {
                          visibility: hidden;
                        }
                      }
                    }
                  }
                  .content {
                    margin-top: 25px;
                    padding: 0 13px 0 13px;
                    @media #{$cr19-large-up} {
                      display: block !important;
                      margin-top: 30px;
                    }
                    .link {
                      text-decoration: underline;
                      font-size: 14px;
                      line-height: 1.2;
                      text-decoration-line: underline;
                      color: $cr19-text;
                      margin-bottom: 18px;
                      a {
                        color: $cr19-text;
                        font-family: $helvetica-regular-font-family;
                      }
                    }
                  }
                }
              }
              .back_to_top {
                display: none !important;
                @media #{$cr19-large-up} {
                  @include border-radius(50%);
                  position: fixed;
                  display: block;
                  bottom: 40px;
                  #{$rdirection}: 80px;
                  background: $color-black;
                  width: 45px;
                  height: 45px;
                  cursor: pointer;
                  z-index: 999;
                  font-size: 14px;
                  .icon-chevron-up {
                    position: relative;
                    top: 13px;
                    font-family: $base-fontawesome-font-family !important;
                    #{$ldirection}: 15px;
                    color: $color-white;
                    border: 0;
                    &:before {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.opc__shipping {
      #main.single .responsive-container .pg_wrapper .column.right {
        #gift-options-display {
          display: none;
        }
      }
    }
    &.opc__review {
      #main.single .responsive-container .pg_wrapper .column.right {
        #gift-options-display {
          display: block;
        }
      }
    }
    &.opc__review,
    &.opc__shipping {
      #main.single .responsive-container .pg_wrapper .column.right {
        .shipping-address-panel,
        .billing-address-panel,
        .shipmethod-panel {
          display: block;
        }
        @media #{$cr19-large-up} {
          .continue-buttons {
            display: block;
          }
        }
      }
    }
  }
  .opc__review,
  .opc__shipping {
    .enhanced-delivery-page #main.single .responsive-container .pg_wrapper .column.right {
      .panel.shipping-address-panel,
      .panel.billing-address-panel,
      .panel.shipmethod-panel {
        display: block;
      }
    }
  }
  .opc__shipping {
    .enhanced-delivery-page #main.single .responsive-container .pg_wrapper .column.right {
      #gift-options-display {
        display: none;
      }
    }
  }
  .opc__review {
    .enhanced-delivery-page #main.single .responsive-container .pg_wrapper .column.right {
      #gift-options-display {
        display: block;
      }
    }
  }
}
