#main {
  #new-account {
    > div {
      float: #{$ldirection};
      width: 46%;
      .user-login-now {
        width: 100%;
      }
      .form-item.email_promotions,
      .form-item.privacy-form-container {
        span {
          width: 80%;
        }
      }
      .loyalty_terms_condition {
        width: 80%;
      }
    }
    .loyalty__block {
      @include swap_direction(margin, 0 0 17px 0px);
      width: 53%;
    }
  }
}

.section-loyalty-offers {
  border-top: 1px solid $color-light-grey;
  .section-content {
    .section-header {
      font-size: 25px;
      font-weight: bold;
      color: $color-cl-soft-black;
      @include breakpoint($ab-small-down) {
        padding: 5px 0;
      }
    }
    .section-header.header-reward-benifits {
      @include swap_direction(padding, 15px 0 0 0);
    }
  }
  .orders-text {
    .grey-color {
      color: $color-darker-grey;
      @include breakpoint($ab-small-down) {
        color: $color-darker-grey;
      }
    }
  }
  .checkout_offer_flexslider {
    position: relative;
    .flex-viewport {
      ul.slides {
        li.slider_list {
          margin-right: 0px;
          .offer-code-wallet__item {
            .offer-code-wallet__code-info--applied {
              @include swap_direction(margin, 15px 35px 40px 35px);
              min-height: 170px;
            }
            .offer_apply {
              input.btn-shopnow.not_meet {
                background: $color-dark-grey;
                cursor: default;
              }
            }
            .offer_title {
              font-size: 25px;
              line-height: 40px;
            }
            .offer_desc {
              min-height: 16px;
            }
            .offer_name {
              font-size: 11px;
              color: $color-shade-dark-grey;
              input.btn-shopnow {
                width: 127px;
                height: 27px;
                @include swap_direction(margin, 20px 0 0 0);
                line-height: 0;
                font-size: 13px;
              }
            }
            span,
            .loyalty__panel__offers__offer-code-wrap {
              display: block;
              @include swap_direction(margin, 0 0 10px 0);
            }
            .more_link {
              font-size: 17px;
              @include swap_direction(margin, 15px 0);
              display: block;
            }
          }
          &.not_meet {
            .offer-code-wallet__code-info--applied {
              img {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    .flex-control-paging {
      position: absolute;
      top: 60%;
      @include breakpoint($ab-small-down) {
        top: 56%;
      }
      text-align: center;
      width: 100%;
      height: 5%;
      li {
        height: 5px;
        width: 5px;
        a {
          background-color: $color-light-grey;
        }
      }
    }
    .flex-direction-nav {
      li a {
        width: 7px;
        top: 25%;
        opacity: 1;
        &.flex-prev {
          left: 0px;
        }
        &.flex-next {
          right: 0px;
        }
        &.flex-disabled {
          display: none;
        }
      }
      @include breakpoint($ab-small-down) {
        display: none;
      }
    }
  }
}

#checkout-right-col {
  .section-loyalty-offers:not(span) {
    border: none;
    @include swap_direction(padding, 0);
  }
}

.review-panel__loyalty-points {
  .review-panel__loyalty-points__description {
    float: none;
    font-weight: inherit;
    @include swap_direction(padding, 0 0 0 10px);
  }
  border-bottom: none;
}

#confirmation-page,
#checkout_shipping_panel,
#completed_checkout_registration {
  fieldset.loyalty_join {
    overflow: visible;
    .pc_loyalty_join_container {
      width: auto;
      .label-content {
        display: inline;
      }
      input[type='checkbox'] {
        margin-right: 0;
      }
    }
  }
}

#loyalty__panel__receipt {
  .loyalty__panel__receipt__submit {
    .loyalty__panel__receipt__submit__option2 {
      display: none;
    }
    .loyalty__panel__receipt__submit__option1,
    .loyalty__panel__receipt__submit__option3 {
      width: 49%;
    }
  }
}
/* To fit addition checkboxes, forced to override already !important height styling used in global brand stylesheet : cl_base/scss/loyalty/_loyalty_sign_in.scss */
body.loyalty_offer_signup {
  #colorbox {
    position: absolute !important;
    width: 535px !important;
    height: 550px !important;
    border: 1px solid $color-dark-grey;
    @include breakpoint($ab-small-down) {
      width: 100% !important;
      height: 570px !important;
    }
    #cboxWrapper {
      height: 550px !important;
      border: none;
      @include breakpoint($ab-small-down) {
        height: 560px !important;
      }
      #cboxContent {
        height: 550px !important;
        width: 450px !important;
        @include breakpoint($ab-small-down) {
          height: 530px !important;
          width: 100% !important;
        }
        .loyalty_popover {
          .loyalty_popover__content {
            .loyalty_popover__checkbox-container {
              float: #{$ldirection};
              width: 100%;
              position: unset;
            }
          }
        }
        #cboxLoadedContent {
          height: 500px !important;
          .loyalty_popover__checkbox-container {
            margin-top: 0.5em;
            p {
              margin-top: 0;
            }
          }
          .tooltip-gdpr {
            position: relative;
            margin: 8px 0;
          }
        }
      }
    }
    .loyalty_popover {
      &__signup {
        .loyalty_popover {
          &__terms {
            #{$ldirection}: 4%;
            top: 17px;
            width: 100%;
            margin: 19px 0 0;
            position: inherit;
          }
        }
      }
    }
  }
  &.noscroll {
    overflow: visible;
  }
}

@include breakpoint($ab-ipad-portrait) {
  #loyalty__panel__perks,
  #loyalty_marketing_points_sections {
    #points_perks {
      padding: 0 10px;
      width: auto;
    }
  }
}

@include breakpoint($ab-small-down) {
  #loyalty__page__index {
    .loyalty__panel__offers__button-container,
    .loyalty__panel__offers__earnpoints__button-container {
      .button {
        height: 45px;
        line-height: 44px;
      }
    }
  }
}

.loyalty_mrkt_ldng {
  .loyalty_market_sticky_nav {
    .gdpr-terms-row {
      &:first-of-type {
        margin-top: 2em;
      }
      margin: 0.75em 0;
      .gdpr-checkbox {
        position: absolute;
        #{$ldirection}: -100vw;
        $checkbox-size: 16px;
        $checkbox-border: 1px solid $color-black;
        + .gdpr-checkbox-label-wrapper {
          position: relative;
          text-indent: calc(#{$checkbox-size} + 5px);
          .for-styled-checkbox::before {
            content: '';
            width: $checkbox-size;
            height: $checkbox-size;
            border: $checkbox-border;
            position: absolute;
            #{$ldirection}: 0;
            top: 0;
          }
        }
        &:checked {
          + .gdpr-checkbox-label-wrapper {
            .for-styled-checkbox::before {
              content: '';
              width: $checkbox-size;
              height: $checkbox-size;
              background-color: $color-black;
              border: $checkbox-border;
              position: absolute;
              #{$ldirection}: 0;
              top: 0;
            }
            .for-styled-checkbox::after {
              content: '';
              position: absolute;
              #{$ldirection}: 5px;
              top: 0;
              width: 5px;
              height: 11px;
              border-style: solid;
              border-color: $color-white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .data-usage-hoverable {
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      .data-usage-content {
        position: absolute;
        bottom: 1.2em;
        #{$ldirection}: 0;
        background-color: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
        border-radius: 5px;
        padding: 15px;
        font-size: 0.85em;
        display: none;
      }
      &:hover,
      &:focus,
      &:active {
        .data-usage-content {
          display: block;
        }
      }
    }
    .loyalty_join_now,
    .loyalty_signin {
      width: auto;
      font-size: 15px;
      padding-right: 50px;
      @include breakpoint($ab-small-down) {
        padding: 0 1em;
      }
      input,
      .error {
        margin: 10px 10px 0 0;
      }
    }
  }
}

body {
  &.device-mobile {
    .registration_content {
      #registration-wrapper {
        .loyalty_form_container {
          margin: 10px 0 15px;
        }
      }
    }
  }
}

// Order Confirmation Enrollment
#confirmation-panel,
#loyalty-enrollment,
#info,
#loyalty_joinalty {
  fieldset.loyalty_join {
    .form_element.radio.pc_loyalty_join_container.checkbox {
      margin: -53px 0 10px 19px;
      width: auto;
      @include breakpoint($ab-small-down) {
        margin: -50px 0 10px 19px;
      }
    }
  }
}

.checkout.checkout-confirmation-page {
  #registration-panel {
    display: none;
  }
}

#confirmation-panel,
#loyalty-enrollment,
#info {
  @include breakpoint($ab-small-down) {
    width: 90%;
    margin: auto;
  }
}

.account-loyalty {
  .section-content {
    .loyalty {
      &__panel {
        &__points {
          &__bg {
            &__slide1,
            &__slide2,
            &__slide3 {
              position: unset;
            }
            &__slide-container {
              border: none;
            }
          }
        }
      }
    }
    .tier-point-msg {
      &.next-tier-points-msg,
      &.next-anniversary-msg {
        margin: 50px 0 0;
      }
    }
  }
}

#loyalty__page__status {
  .loyalty_status_point {
    .account-loyalty {
      .loyalty__panel__points__button {
        a.view-all {
          margin-top: 10%;
        }
      }
    }
  }
}

body.page-iframe {
  #main {
    padding-bottom: 40px;
  }
}
