.legal_frame {
  margin-top: 10px;
  width: 100%;
  height: 10rem;
  border: 1px solid $color-darkest-grey;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-word;
}

.presales-container {
  background: $color-white;
  word-break: break-word;
}

.section-data-privacy-statement {
  #page-header-wrapper,
  .page-footer-region,
  .header-gnav-navigation {
    display: none;
  }
  .LPMcontainer,
  .optanon-alert-box-wrapper {
    display: none !important;
  }
}

.registration_content {
  .full-width-wrap {
    fieldset {
      &.fs {
        &.sms_promotions {
          label {
            text-transform: none;
          }
          .phone_container {
            input[type='text'] {
              margin: 0;
            }
            input {
              &.field {
                &.error {
                  border: 1px solid $color-red !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

#newsletter-info {
  .sms_promotions {
    margin-top: 0;
  }
  .pc_email_promo_container {
    padding-bottom: 10px;
  }
}

#signin {
  #signin-block {
    input[type='checkbox'] {
      width: auto;
    }
    input[type='text'],
    input[type='tel'] {
      display: block;
    }
    .password_container {
      input[type='text'] {
        display: inline;
      }
    }
  }
  .signin_container {
    .form-item {
      .label {
        display: inline;
      }
      .sms_input {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

#signin-block {
  .label {
    &.error {
      .label-content {
        color: $color-red;
      }
    }
  }
}

.passwordreset {
  .label {
    &.error {
      color: $color-red;
    }
  }
}

.spc_enabled {
  #main {
    &.single {
      .sign-in-panel {
        input {
          width: auto;
        }
        .checkout {
          &__return-user,
          &__new-account {
            .form-submit {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#profile_preferences {
  #newsletter-info {
    .sms_promotions {
      .form_element {
        &.radio {
          label {
            width: auto;
          }
        }
      }
    }
  }
}

.error {
  span {
    color: $color-red;
    a {
      color: $color-red;
      text-decoration: underline;
    }
  }
}
