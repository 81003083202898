/* Added for Power reviews */
#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          width: 80%;
          .pr-rd-flag-review-container {
            width: auto;
          }
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Evet ';
                visibility: visible;
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Yok hayır ';
                visibility: visible;
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &::before {
            content: 'Filtre:';
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              content: 'Göre sırala: ';
            }
          }
        }
        .pr-rd-sort {
          background-position: 95%;
        }
      }
    }
  }
}

.write_a_review__container {
  .pr-cb-tag-container {
    .pr-label-control {
      span {
        white-space: pre-wrap;
      }
    }
  }
}
