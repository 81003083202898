#confirm.device-mobile {
  .checkout-confirmation-page {
    .social-login {
      .social-login__container {
        border-top: 1px solid $color-shade-grey;
        text-align: left;
        padding-left: 10px;
        border-bottom: 1px solid $color-shade-grey;
        h2 {
          font-size: 26px;
        }
      }
      .social-login__info {
        padding-bottom: 20px;
      }
      .social-login__email_opt_in {
        padding: 0;
        .social-login__opt-in-label {
          margin-top: 0 !important;
        }
      }
      label {
        margin-#{$ldirection}: 10px;
        margin-top: 0;
        color: #{$color-black};
      }
    }
  }
}

#confirm {
  .checkout-confirmation-page {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          height: auto;
          width: auto;
          top: 0;
          margin-#{$rdirection}: 0;
        }
        display: inline-flex;
        .social-login__email-opt-in-label {
          font-weight: normal;
        }
      }
      .social-login__divider,
      .social-login__terms {
        display: none;
      }
      .social-login__title {
        display: block;
      }
      .social-login__info {
        margin-bottom: 10px;
        display: block;
      }
    }
    .fb-overlay-container {
      display: inline-flex;
    }
    .fb-disclaimer-container {
      margin-top: 15px;
      input {
        width: 35px;
        @media #{$medium-up} {
          width: auto;
        }
      }
    }
  }
}

.gnav-my-account-v1 {
  @media #{$xlarge-only} {
    height: 500px;
    overflow-y: scroll;
  }
  .social-login.gnav {
    .social-login__divider {
      .inner {
        background: $color-cl-or-background;
      }
    }
    .fb-disclaimer-container {
      @media #{$medium-up} {
        display: flex;
      }
    }
    .social-login__email-opt-in {
      .social-login__email-opt-in-label {
        padding-#{$ldirection}: 10px;
      }
    }
  }
}

#registration.device-mobile {
  .registration_content {
    .social-info {
      margin-top: 0;
      .social-info__content {
        padding-top: 10px;
        p {
          margin-bottom: 24px;
        }
      }
      .social-info__connect {
        margin-bottom: 17px;
      }
    }
  }
}
/* Account Signin Page */
.device-mobile,
.signin_container {
  .social-login {
    .social-login__email_opt_in {
      text-align: #{$ldirection};
      margin-top: 20px;
      .social-login__opt-in-label {
        padding-#{$ldirection}: 10px;
      }
    }
    .social-login__terms {
      text-align: #{$ldirection};
      padding: 0;
    }
  }
  .signin-social-section,
  .divider {
    display: none;
  }
  .facebook-button {
    display: none;
  }
  .tooltip {
    text-align: left;
    margin-top: 10px;
  }
  .disclaimer_tooltip {
    display: none;
  }
  .fb-disclaimer-container {
    margin-top: 20px;
    display: flex;
    .fb-disclaimer {
      margin-#{$ldirection}: 10px;
    }
  }
}
/* Account Profile Page FB Status */
.account-header-panel {
  .account-profile__connected-accounts {
    @include swap_direction(padding, 5px 0 0 8px);
    opacity: 0.8;
    color: $color-darker-grey;
  }
}

.social-info {
  .social-info__connect {
    margin-bottom: 15px;
    display: inline-flex;
  }
  input.form-submit {
    margin: 0;
  }
  .tooltip {
    margin-bottom: 10px;
  }
}
/* Checkout Index Page */
.device-mobile,
.responsive-container.checkout {
  .checkout__content {
    .social-login {
      .social-login__divider {
        width: 92%;
        @media #{$medium-up} {
          width: 100%;
        }
      }
      .social-login__email_opt_in {
        text-align: #{$ldirection};
        .social-login__opt-in-checkbox {
          width: auto !important;
          float: #{$ldirection};
        }
      }
      .social-login__terms {
        text-align: #{$ldirection};
        padding: 0 15px;
        @media #{$medium-up} {
          padding: 0;
        }
      }
    }
    .tooltip {
      margin-#{$ldirection}: 10px;
    }
  }
  .facebook-button {
    display: none;
  }
}

.facebook-logo {
  display: none;
}

.social-login__email-opt-in {
  text-align: $ldirection;
}

#signin {
  .signin_container {
    form#facebook_signin {
      label {
        display: inline;
      }
    }
  }
  #footer {
    position: relative;
  }
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          margin-top: 0;
          width: 37px;
          @media #{$medium-up} {
            width: auto;
          }
        }
      }
    }
    input[type='checkbox'] {
      width: 35px;
      @media #{$medium-up} {
        width: auto;
      }
    }
  }
}

.spc_enabled {
  #main.single {
    .sign-in-panel {
      input[type='checkbox'].js-facebook_email_opt_in {
        width: 15px;
        float: $ldirection;
        margin-#{$rdirection}: 10px;
        @media #{$medium-up} {
          width: auto;
        }
      }
      .social-login {
        .social-login__email-opt-in {
          @include swap_direction(margin, 20px 15px 0 15px);
          @media #{$medium-up} {
            margin-#{$ldirection}: 0;
          }
          .social-login__email-opt-in-label {
            padding-#{$ldirection}: 0;
            @media #{$medium-up} {
              padding-#{$ldirection}: 20px;
            }
          }
        }
      }
      .fb-disclaimer-container {
        display: inline-flex;
        padding: 0 15px;
        @media #{$medium-up} {
          padding: 20px 0 10px;
        }
        input[type='checkbox'] {
          width: 35px;
          height: 35px;
          @media #{$medium-up} {
            width: auto;
            height: auto;
            margin-top: 3px;
            margin-#{$rdirection}: 10px;
          }
        }
      }
    }
    .responsive-container {
      .panel {
        label.social-login__email-opt-in-label {
          padding-#{$ldirection}: 12px;
          @media #{$medium-up} {
            padding-#{$ldirection}: 24px;
            padding-top: 7px;
          }
        }
      }
    }
  }
}

.not-front.not-logged-in {
  .gnav-my-account-v1 {
    .signin_container {
      .social-login {
        display: none;
      }
    }
  }
}

.not-front.not-logged-in.elc-user-state-anonymous {
  .gnav-my-account-v1 {
    .signin_container {
      .social-login {
        display: block;
      }
    }
  }
}
/* Book Appointment Page */
.appointments-container {
  .sign-in-container {
    .sign-in-form-container {
      .social-login {
        .social-login__email-opt-in {
          display: flex;
          margin-top: 15px;
          .social-login__opt-in-checkbox {
            width: auto;
            margin-bottom: 0;
          }
          .social-login__opt-in-label {
            margin-top: 10px;
          }
        }
        .social-login__terms {
          margin-top: 10px;
          text-align: left;
          padding: 0;
        }
        .fb-disclaimer-container {
          display: flex;
          .fb-disclaimer {
            margin-top: 10px;
          }
        }
        input[type='checkbox'] {
          width: auto;
        }
      }
    }
  }
}
/* Tooltip */
.social-login,
.social-info {
  .tooltip {
    text-align: left;
    margin-top: 10px;
    position: relative;
    display: inline-block;
    width: 98%;
    font-weight: bold;
    font-family: $helvetica-regular-font-family;
    a {
      color: $color-white;
      text-decoration: underline;
    }
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: hidden;
      width: 98%;
      background-color: $color-cl-davy-grey;
      color: $color-white !important;
      text-align: center;
      border-radius: 6px;
      padding: 10px 5px;
      position: absolute;
      z-index: 1;
      left: 0%;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: normal;
    }
    .tooltiptext-under {
      top: 125%;
    }
    .tooltiptext-over {
      bottom: 125%;
    }
    .tooltiptext-under::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-cl-davy-grey transparent;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-cl-davy-grey transparent transparent transparent;
    }
    &:hover .tooltiptext-under,
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
  &__terms {
    display: none;
  }
  .disclaimer_tooltip {
    display: none;
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.social-login {
  label {
    text-transform: none;
    text-align: $ldirection;
  }
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}
/* Appointment page */
#appointment-book-container.appt-book {
  .appointments-container {
    .sign-in-container {
      overflow: inherit;
      .sign-in-form-container {
        overflow: inherit !important;
        .social-login {
          .fb-disclaimer-container {
            @media #{$medium-up} {
              margin-top: 10px;
            }
            display: flex;
            input {
              width: 48.2px;
              margin-#{$rdirection}: 8px;
              margin-bottom: 0;
              @media #{$medium-up} {
                width: 20px;
              }
            }
          }
          .social-login__email-opt-in {
            .social-login__opt-in-checkbox {
              width: 15px;
              @media #{$medium-up} {
                width: 20px;
              }
            }
          }
          .tooltip-disclaimer {
            display: none;
          }
        }
      }
    }
  }
}

.social-login-appointment {
  display: block;
}
