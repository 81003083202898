// Setting Defaults for Adaptive Placeholder
$adaptive-placeholder: (
  height: 36px,
  margin: 5px,
  border-radius: 0,
  font-size: 13px,
  border: 1px
);

.checkout__content {
  .with-field-lookup {
    .postcode-lookup {
      width: 37%;
    }
    .find-town {
      width: 61%;
      .valid_marker,
      .invalid_marker {
        top: 2px;
      }
      .city-results {
        margin-right: 0;
      }
    }
  }
}
