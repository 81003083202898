#main.single {
  .responsive-container {
    #review-panel {
      .payment-type {
        input[type='radio'] {
          margin-top: 0;
          clear: left;
        }
        label {
          float: left;
        }
      }
    }
  }
}

section#samples-panel {
  .samples {
    ul.samples-grid.product-list {
      .product__container {
        padding-bottom: 40px;
        padding-top: 15px;
      }
    }
  }
}
