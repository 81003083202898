@if $password_strength {
  #gnav_signin {
    @media #{$medium-up} {
      .signin_container {
        .row {
          overflow: visible;
        }
      }
      .password-field {
        &__info {
          width: 18%;
        }
      }
    }
  }
  #registration {
    #foreground-node {
      margin-#{$ldirection}: 13.5px !important;
    }
  }
}
