$medium-down: '#{$screen} and (max-width: 1024px)';
$ipad-only: '#{$screen} and (min-width:765px) and (max-width:790px)';

#sms_promotion_container + * {
  clear: both;
}

.label_error {
  color: $color-red;
}

.slick-list {
  .slick-track {
    .slick-slide {
      min-height: 0 !important;
    }
  }
}

#header-gnav-navigation .gnav-header-formatter__sections-panel.visible {
  overflow-y: auto;
}

#registration {
  #foreground-node {
    &.loyalty-cancellation-confirm-overlay,
    &.loyalty-cancel-overlay {
      @media #{$medium-up} {
        margin-left: 25% !important;
      }
    }
  }
}

@media #{$small-only} {
  .loyalty_mrkt_ldng {
    .loyalty_market_points {
      .outer-wrap {
        padding: 0;
      }
    }
    .editorial-grid-formatter__grid > div {
      padding: 0;
    }
    #node-326785 {
      .loyalty_market_sticky_nav {
        div.loyalty_signin {
          padding-right: 0;
          padding-top: 17px;
        }
      }
    }
    #node-326793 {
      .split-width-text-tout {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .editorial-grid-formatter__container {
      .text-tout-full-width {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .account-loyalty {
    .section-content {
      .tier-point-msg.next-tier-points-msg {
        margin: 0;
      }
    }
  }
}

.loyalty-join-panel {
  &__subtitle {
    font-weight: 700;
  }
}

.panel {
  .top-banner {
    display: none;
  }
  &--simple {
    .actions {
      padding: 1em 0;
    }
  }
}

//Welcome Popup
body.loyalty_offer_add_to_bag {
  #cboxTopLeft,
  #cboxTopCenter,
  #cboxTopRight,
  #cboxMiddleRight,
  #cboxBottomLeft,
  #cboxBottomCenter,
  #cboxBottomRight,
  #cboxMiddleLeft {
    @if $new-loyalty-popup == true {
      display: none;
    }
  }
}

body.loyalty_offer_signup,
body.loyalty_offer_add_to_bag {
  #colorbox {
    position: fixed !important;
    top: 120px !important;
    left: 390px !important;
    width: 539px !important;
    height: 450px !important;
    padding: 0 !important;
    @include breakpoint($ab-small-down) {
      top: 0% !important;
      left: auto !important;
      width: 100% !important;
      height: 485px !important;
      margin-#{$ldirection}: 0px;
      background: $color-white;
    }
    #cboxWrapper {
      border: 1px solid $color-dark-grey;
      width: 535px !important;
      height: 420px !important;
      @include breakpoint($ab-small-down) {
        width: 100% !important;
        height: auto !important;
        border: 0px;
        padding: 0 20px;
        box-sizing: border-box;
      }
      #cboxContent {
        width: 433px !important;
        height: 375px !important;
        @include breakpoint($ab-small-down) {
          width: 100% !important;
          height: auto !important;
          padding: 60px 0 0 0;
        }
        #cboxLoadedContent {
          width: 433px !important;
          height: 340px !important;
          @include breakpoint($ab-small-down) {
            width: 100% !important;
            height: 420px !important;
          }
          @if $new-loyalty-popup == true {
            width: 570px !important;
            height: 520px !important;
            @include breakpoint($ab-small-down) {
              height: 450px !important;
            }
          }
          @if $new-loyalty-popup == true {
            .loyalty_popover__checkbox-container {
              margin-top: 0;
              .for-styled-checkbox {
                width: 100%;
              }
            }
          }
        }
        @if $new-loyalty-popup == true {
          padding: 24px;
          width: 575px !important;
          height: 510px !important;
          @include breakpoint($ab-small-down) {
            padding: 30px 20px;
            width: 90% !important;
          }
        }
        @if $new-loyalty-popup == true {
          .loyalty_popover {
            .loyalty_popover__content {
              .loyalty_popover__sub-header {
                margin: 25px 0 10px 0;
              }
              .loyalty_popover__input.is-alternate {
                margin: 14px 0 0 0;
              }
              .loyalty_popover__input {
                .loyalty_popover__submit-container {
                  .form-text {
                    @include breakpoint($ab-small-down) {
                      width: 55%;
                    }
                  }
                  .form-submit {
                    margin-left: 4px;
                  }
                }
              }
            }
          }
        }
      }
      @if $new-loyalty-popup == true {
        width: 624px !important;
        height: 560px !important;
        @include breakpoint($ab-small-down) {
          padding: 0;
        }
      }
    }
    @if $new-loyalty-popup == true {
      #{$ldirection}: 50% !important;
      padding: 0 !important;
      width: 624px !important;
      height: 560px !important;
      transform: translate(-50%, 0);
      @include breakpoint($cr19-small) {
        transform: translate(0, 0);
      }
    }
  }
}

@media #{$ipad-only} {
  .loyalty_mrkt_ldng {
    .loyalty-grid-formatter__legend {
      display: block;
    }
    .loyalty-grid-formatter__legend--mobile {
      display: none;
    }
    #node-326795 .text-tout-full-width__inner {
      padding-left: 25px;
    }
  }
  .loyalty__panel__points__history {
    .account-loyalty {
      .section-content {
        .tier-point-msg.next-anniversary-msg {
          float: right;
          margin-top: 11px;
        }
      }
    }
  }
  .account-section.loyalty-page {
    .loyalty_tab_menu {
      .loyalty__panel_tier-benifits-rows {
        .row .loyalty__panel__points__history {
          .loyalty__panel__points__points__details {
            margin: 70px 0 0 0;
          }
        }
      }
    }
  }
}

.account-loyalty {
  .section-content {
    .tier-point-msg.next-tier-points-msg {
      margin-left: 0;
    }
  }
}

#landing.is-loyalty-member {
  .account.breadcrumb {
    padding-bottom: 550px;
  }
}

.checkout {
  #confirmation-panel {
    .confirmation-mail-text {
      @media #{$medium-up} {
        padding-left: 0;
      }
    }
    #loyalty-points-online {
      display: none;
    }
  }
}

.account-section.loyalty-page .loyalty-page__content {
  #loyalty__panel__offers__sku {
    display: inline-block;
    ul.slides li .loyalty__panel__offers__sku__cta .button--offers {
      padding: 10px 0 15px;
    }
  }
}

.account-section.loyalty-page .loyalty-page__content #loyalty__panel__perks {
  display: initial;
}

.loyalty__panel__points__bg__slide-container {
  display: none;
}

.confirmation-panel__container {
  #loyalty-points-confirm {
    padding-left: 0;
  }
}

.spc_enabled {
  #main.single {
    .responsive-container {
      #confirmation-page {
        #confirmation-panel {
          .actions {
            @media #{$medium-up} {
              padding-left: 0;
            }
          }
        }
      }
      .panel {
        .content {
          padding-left: 0;
        }
        header {
          h2 {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.tier-point-msg.next-anniversary-msg {
  clear: both;
}

// Order Confirmation Redesign
.checkout.checkout-confirmation-page {
  #loyalty-join-panel,
  #registration-panel {
    display: none;
  }
}

.confirmation-panel__container {
  #info {
    @media #{$medium-down} {
      width: 90%;
      margin: auto;
      fieldset.fs p {
        margin-top: 12px;
      }
    }
    .loyalty_list {
      font-size: 17px;
    }
  }
}

.loyalty-offer-section {
  text-align: left;
}

#offer_code {
  .offer-code-wallet__item {
    @media #{$medium-up} {
      display: flex;
      padding: 0 50px;
    }
    .offer-code-wallet__code-info--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-left: 40px;
      @media #{$small-only} {
        align-items: center;
        margin-top: 15px;
        margin-left: inherit;
      }
      .offer_apply input[type='submit'] {
        padding: 10px 40px;
        font-weight: 700;
        height: initial;
        cursor: pointer;
        @media #{$small-only} {
          border-radius: 10px;
          height: inherit;
        }
      }
    }
  }
}

.offer_count_text {
  line-height: 1.7;
  @media #{$small-only} {
    text-align: center;
  }
  .more_link {
    color: $color-text-black;
    text-decoration: underline;
  }
}

.checkout_loyalty_offer_flexslider {
  position: relative;
  margin-top: 30px;
  .slider_list {
    margin: 0;
  }
}

.flex-direction-nav {
  position: absolute;
  top: 45%;
  width: 100%;
  @media #{$small-only} {
    top: 30%;
  }
}

.flex-control-nav {
  position: initial;
  margin-top: 15px;
}
